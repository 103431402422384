import React from "react";

const SocialDiscordSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31.984" viewBox="0 0 32 31.984">
      <g id="Group_536" data-name="Group 536" transform="translate(-2054 -5354.828)">
        <path id="Path_6696" data-name="Path 6696" d="M0,17.948V15.7c.02-.08.046-.159.058-.24.106-.7.172-1.4.321-2.091a16,16,0,0,1,31.4,6.078,15.791,15.791,0,0,1-4.962,9.143A15.6,15.6,0,0,1,18.8,32.564c-.56.1-1.126.166-1.689.248H14.867c-.358-.046-.718-.088-1.075-.14a15.784,15.784,0,0,1-8.248-3.749A15.72,15.72,0,0,1,.249,19.639c-.1-.56-.167-1.127-.249-1.69M9.7,22.7c.3-.408.389-.434.784-.263a13.82,13.82,0,0,0,4.545,1.124,13.669,13.669,0,0,0,6.478-1.133c.375-.163.47-.132.766.291l-1.941.938c.371.6.739,1.171,1.091,1.757a.307.307,0,0,0,.431.145,19.837,19.837,0,0,0,5.3-2.661.688.688,0,0,0,.32-.559,20.1,20.1,0,0,0-.131-4.483A19.536,19.536,0,0,0,24.12,9.666a.747.747,0,0,0-.3-.256A19,19,0,0,0,19.5,8.06a.287.287,0,0,0-.379.193c-.116.255-.257.5-.36.759a.3.3,0,0,1-.391.222,16.717,16.717,0,0,0-4.728,0A.316.316,0,0,1,13.225,9a5.62,5.62,0,0,0-.413-.8.331.331,0,0,0-.28-.128c-1.073.28-2.156.532-3.207.879-1.385.457-1.374.49-2.11,1.732A19.383,19.383,0,0,0,4.5,22.294a.735.735,0,0,0,.337.614A19.676,19.676,0,0,0,9.668,25.4c.73.254.736.259,1.148-.4.279-.444.537-.9.816-1.37-.372-.165-.687-.3-.994-.443s-.621-.323-.936-.487" transform="translate(2054 5354)"/>
        <path id="Path_6700" data-name="Path 6700" d="M285.814,232.1a1.938,1.938,0,0,1,1.639.868,2.557,2.557,0,0,1-.125,3.071,1.928,1.928,0,0,1-2.956.057,2.475,2.475,0,0,1,.367-3.649,5.787,5.787,0,0,1,1.041-.429l.034.082" transform="translate(1788.017 5137.272)"/>
        <path id="Path_6701" data-name="Path 6701" d="M165.238,235.658a2.38,2.38,0,0,1-1.179,2.164,2.057,2.057,0,0,1-2.948-1.6,2.621,2.621,0,0,1,.112-1.378,2.045,2.045,0,0,1,2.1-1.433,2.131,2.131,0,0,1,1.84,1.7c.044.2.059.409.079.553" transform="translate(1903.011 5135.975)"/>
      </g>
    </svg>
  );
};

export default SocialDiscordSvg;


