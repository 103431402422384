import React, {useState} from 'react';

const ChangePassword = ({staticBasePath, setChangePassword}) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('old_password', oldPassword);
    formData.append('new_password1', newPassword1);
    formData.append('new_password2', newPassword2);
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    fetch('/accounts/password/change/', {
      method: 'POST',
      headers: {

        'X-CSRFToken': csrfToken,
      },

      body: formData,
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then(data => {
            throw new Error(data.errors ? JSON.stringify(data.errors) : 'Failed to change password');
          });
        }
      })
      .then(data => {
        setMessage(data.message);
        setErrors("");
        setTimeout(() => {
          setChangePassword(false);
        }, 2000);
      })
      .catch(error => {
        setErrors(JSON.parse(error.message));
        setMessage("");
      });
  };
  const handleButtonClickPopupHide = () => {
    setChangePassword(false);
  };
  return (
    <>
      <div className="user-change-password-bg"
           onClick={() =>
             handleButtonClickPopupHide()
           }
      ></div>
      <div className="user-change-password-wrap">
        <img
          src={`${staticBasePath}public/images/svg/rb_close.svg`}
          alt="Close"
          onClick={() =>
            handleButtonClickPopupHide()
          }
        />

        <div className="user-change-password__title">
          Change Password
        </div>
        <div className="user-change-password__block rb-display-flex rb-justify-content-center rb-flex-wrap-wrap">
          {message && <p className="success-message">{message}</p>}
          <form className="user-change-password__block-form" onSubmit={handleSubmit}>
            <div className="user-change-password__block-form-field">
              <label>Old Password:</label>
              <input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className={errors.old_password ? 'input-error rb-input' : 'rb-input'}
              />
              {errors.old_password && <p className="error-message">{errors.old_password}</p>}
            </div>

            <div className="user-change-password__block-form-field">
              <label>New Password:</label>
              <input
                type="password"
                value={newPassword1}
                onChange={(e) => setNewPassword1(e.target.value)}
                className={errors.new_password1 ? 'input-error rb-input' : 'rb-input'}
              />
              {errors.new_password1 && <p className="error-message">{errors.new_password1}</p>}
            </div>

            <div className="user-change-password__block-form-field">
              <label>Confirm New Password:</label>
              <input
                type="password"
                value={newPassword2}
                onChange={(e) => setNewPassword2(e.target.value)}
                className={errors.new_password2 ? 'input-error rb-input' : 'rb-input'}
              />
              {errors.new_password2 && <p className="error-message">{errors.new_password2}</p>}
            </div>

            <button className="rb-btn rb-btn-black" type="submit">Change Password</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
