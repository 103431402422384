import PropTypes from "prop-types";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SubscriptionSuccess from "../components/SubscriptionSuccess";

const SubscriptionSuccessPage = ({ staticBasePath, djangoContext }) => {
  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext} />
      <div className="rb-main-content pricing-page">
        <div className="rb-container">
          <SubscriptionSuccess staticBasePath={staticBasePath} />
        </div>
      </div>
      <Footer staticBasePath={staticBasePath} />
    </>
  );
};
SubscriptionSuccessPage.propTypes = {
  staticBasePath: PropTypes.object.isRequired,
  djangoContext: PropTypes.object.isRequired,
};
export default SubscriptionSuccessPage;
