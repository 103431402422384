import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { URLs } from "../../utils/apis";

const QuotaLimit = ({ staticBasePath, setQuotaShow, quotaUsageInfo }) => {
  // Function to handle closing the quota limit banner
  const handleClickClose = () => {
    setQuotaShow(false);
    document.body.style.overflow = "";
    // Set a cookie to remember that the user has closed the banner
    Cookies.set("quotaHide", JSON.stringify(true));
  };

  return (
    <div className="quota-limit-wrap">
      <img
        onClick={() => handleClickClose()}
        className="quota-limit-close"
        src={`${staticBasePath}public/images/svg/rb_close.svg`}
        alt="Close"
      />
      <div className="quota-limit">
        You've used {quotaUsageInfo}% of your stamp quota. Consider upgrading
        your <a href={URLs.PROFILE_PAYMENT_URL}>plan</a>
      </div>
    </div>
  );
};

export default QuotaLimit;
