import React from "react";
import LendingPricingSvg from "../../svg/LendingPricingSvg";


const TransformDataSecurity = ({staticBasePath}) => {
  return (
    <section className="transform-data-security">
      <div className="landing__container">
        <div className="transform-data-security__blocks rb-display-flex rb-align-items-center">
        <div className="transform-data-security__blocks-texts">
<span>Transform Your Data Security
with vBase</span>
          <p>Establishes the exact time of digital record creation, providing a dependable and unchangeable history of
            events.</p>
          <div
            className="landing-buttons rb-display-flex  rb-align-items-center">
            <a className="button rb-btn button-white rb-display-flex rb-align-items-center"
               target="_blank"
               href="/accounts/signup/">
              Begin Today
              <LendingPricingSvg/>
            </a>
          </div>
        </div>
        <div className="transform-data-security__blocks-img">
          <img
            src={`${staticBasePath}public/images/svg/landing_transform_data_security.svg`}
            alt="Transform Your Data Security with vBase"
          />
        </div>
        </div>
      </div>
    </section>
  )
    ;
};

export default TransformDataSecurity;
