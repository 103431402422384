import React, { useState, useEffect, useRef } from "react";

const CustomerReviews = ({staticBasePath}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const sliderRef = useRef(null);
  const slidesRef = useRef([]);

  useEffect(() => {
    const updateSlideDimensions = () => {
      const sliderWidth = sliderRef.current.offsetWidth;
      setSlideWidth(sliderWidth);
    };

    updateSlideDimensions();
    window.addEventListener("resize", updateSlideDimensions);

    return () => window.removeEventListener("resize", updateSlideDimensions);
  }, []);

  const nextSlide = () => {
    setSlideIndex(prevIndex =>
      prevIndex + 1 < slidesRef.current.length ? prevIndex + 1 : 0
    );
  };

  const prevSlide = () => {
    setSlideIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : slidesRef.current.length - 1
    );
  };
  return (
    <section className="customer__reviews blocks-reviews">
      <div className="landing__container">
        <div className="customer__reviews-title">Customer Reviews</div>


        <div className="blocks-reviews-slider rb-display-flex rb-align-items-center rb-justify-content-space-between">

          <div className="blocks-reviews-nav rb-display-flex">
            <button className="blocks-reviews-nav-button rb-display-flex blocks-blog-nav-prev blocks-reviews-nav-prev reviews-prev-js"
                    aria-label="Prev" onClick={prevSlide}>
              <img
                src={`${staticBasePath}public/images/svg/landing_reviews-arrow.svg`}
                alt="Arrow left"
              />
            </button>
          </div>

          <div className="reviews-wrap">
            <div className="blocks-reviews-slider-slides reviews-slides-js rb-display-flex reviews"

                 ref={sliderRef}
                 style={{
                   display: "flex",
                   transform: `translateX(-${slideIndex * slideWidth}px)`,
                   transition: "transform 0.5s ease",
                 }}
            >


              <div className="reviews-item reviews-slide-js"
                   ref={el => slidesRef.current[0] = el}
                   style={{ minWidth: slideWidth, flexShrink: 0 }}
              >

                <p>"If a data vendor is even considering using validityBase, we want to know about them. It sends a very positive signal about the quality of their data."</p>

                <div className="reviews-item-author rb-display-flex rb-align-items-center">

                  <div className="reviews-item-author-photo">

                    <img width="48"
                      src={`${staticBasePath}public/images/png/CIO-image-48x48.png`}
                      alt="Robert Fox"
                    />
                  </div>

                  <div className="reviews-item-author-name">
                    CIO
                    <span>London ML/AI quantitative fund</span>
                  </div>
                </div>
              </div>

              <div className="reviews-item reviews-slide-js"
                   ref={el => slidesRef.current[1] = el}
                   style={{ minWidth: slideWidth, flexShrink: 0 }}>

                <p>"vBase really helps a newer data product like mine gain credibility and compete with more established companies."</p>

                <div className="reviews-item-author rb-display-flex rb-align-items-center">

                  <div className="reviews-item-author-photo">

                    <img width="48"
                         src={`${staticBasePath}public/images/png/survey-icon-1-48x48.png`}
                         alt="Robert Fox"
                    />
                  </div>

                  <div className="reviews-item-author-name">
                    Founder
                    <span>US financial survey data company</span>
                  </div>
                </div>
              </div>

              <div className="reviews-item reviews-slide-js"
                   ref={el => slidesRef.current[2] = el}
                   style={{ minWidth: slideWidth, flexShrink: 0 }}>

                <p>"vBase is a great tool for creating a credible track record. Thank you."</p>

                <div className="reviews-item-author rb-display-flex rb-align-items-center">

                  <div className="reviews-item-author-photo">

                    <img width="48"
                         src={`${staticBasePath}public/images/png/quant-fund-icon-48x48.png`}
                         alt="Robert Fox"
                    />
                  </div>

                  <div className="reviews-item-author-name">
                    Founder
                    <span>US-based quant fund</span>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="blocks-reviews-nav rb-display-flex">

            <button className="blocks-reviews-nav-button  rb-display-flex blocks-blog-nav-next blocks-reviews-nav-next reviews-next-js"
                    aria-label="Next" onClick={nextSlide}>

              <img
                src={`${staticBasePath}public/images/svg/landing_reviews-arrow.svg`}
                alt="Arrow right"
              />
            </button>
          </div>
        </div>


      </div>
    </section>
  )
    ;
};

export default CustomerReviews;
