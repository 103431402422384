export const formatCollectionUserFound = (name) => {
  if (!name || typeof name !== "string") {
    return { formatted: "n/a", title_hover: false };
  }

  const result = {
    formatted: name,
    title_hover: false,
  };

  if (window.innerWidth < 768 && name.length > 28) {
    result.formatted = `${name.slice(0, 7)} ... ${name.slice(-6)}`;
    result.title_hover = true;
  } else if (name.length > 70) {
    result.formatted = `${name.slice(0, 40)} ... ${name.slice(-20)}`;
    result.title_hover = true;
  }

  return result;
};

export const formatCollectionUserFoundPDF = (
  value,
  left = 20,
  right = 7,
  length = 28,
) => {
  if (value.length > length) {
    value = `${value.slice(0, left)} ... ${value.slice(-right)}`;
  }

  return value;
};

export const formatHashCollectionUserFound = (value, left = 5, right = 4) => {
  if (value.length > 15) {
    return `${value.slice(0, left)}...${value.slice(-right)}`;
  }
  return value;
};
