import React from "react";

const SocialGithubSvg = () => {
  return (
    <svg width="32" height="31.21" viewBox="0 0 32 31.21" id="icon-github" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" transform="translate(-2 -2)">
          <g id="UI_icons_dark_github" data-name="UI/icons/dark/github" transform="translate(2 2)">
            <path id="icons_icon-github" data-name="icons/icon-github"
                  d="M18,2a16,16,0,0,0-5.057,31.182c.8.147,1.092-.347,1.092-.771,0-.38-.014-1.386-.022-2.721-4.45.967-5.389-2.145-5.389-2.145A4.238,4.238,0,0,0,6.846,25.2c-1.453-.992.11-.972.11-.972a3.359,3.359,0,0,1,2.451,1.649,3.407,3.407,0,0,0,4.657,1.329,3.42,3.42,0,0,1,1.016-2.138c-3.553-.4-7.288-1.777-7.288-7.908A6.184,6.184,0,0,1,9.438,12.87,5.749,5.749,0,0,1,9.6,8.636s1.343-.43,4.4,1.64a15.146,15.146,0,0,1,8.011,0c3.055-2.071,4.4-1.64,4.4-1.64a5.75,5.75,0,0,1,.16,4.234,6.173,6.173,0,0,1,1.644,4.293c0,6.147-3.741,7.5-7.3,7.9a3.821,3.821,0,0,1,1.085,2.962c0,2.139-.02,3.865-.02,4.39,0,.428.289.926,1.1.77A16,16,0,0,0,18,2"
                  transform="translate(-2 -2)" fillRule="evenodd"/>
          </g>
        </g>
    </svg>
  );
};

export default SocialGithubSvg;


