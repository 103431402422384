import React from "react";

const StampFromFile = () => {
  return (
    <svg
      className="rb-btn-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="17.249"
      height="20.996"
      viewBox="0 0 17.249 20.996"
    >
      <path
        d="M58.749,0h10.39a1.912,1.912,0,0,1,.32.214Q71.307,2.052,73.147,3.9a.681.681,0,0,1,.211.506q0,6.854,0,13.708A1.857,1.857,0,0,1,71.474,20Q65.236,20,59,19.99a1.978,1.978,0,0,1-.824-.19,1.808,1.808,0,0,1-1.064-1.761q0-8.035,0-16.07c0-.059,0-.117,0-.176A1.88,1.88,0,0,1,57.77.45,2.226,2.226,0,0,1,58.749,0m13.36,5h-.229c-.735,0-1.471,0-2.207,0a1.245,1.245,0,0,1-1.314-1.307c0-.742,0-1.484,0-2.226V1.249h-9.2c-.57,0-.8.224-.8.788V17.954c0,.569.224.8.789.8H71.313c.569,0,.8-.224.8-.79V5Z"
        stroke="rgba(0,0,0,0)"
        transform="translate(-56.609 0.5)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
};

export default StampFromFile;
