import { URLs, fetchUserData } from "./apis";
export const handleButtonClickUserProfile = async (
  index,
  buttonTexts,
  setDisabledInputs,
  setButtonTexts,
  inputValue,
  field,
  setChangedUserName,
  handleErrorText,
) => {
  // Basic validation for Username
  if (inputValue.trim() === "") {
    handleErrorText("Field cannot be empty");
    setTimeout(() => {
      handleErrorText("");
    }, 2000);
    return;
  }

  if (inputValue.length < 3) {
    handleErrorText("Input must be at least 3 characters");
    setTimeout(() => {
      handleErrorText("");
    }, 2000);
    return;
  }

  if (inputValue.length > 70) {
    handleErrorText("Input must be less than 70 characters");
    setTimeout(() => {
      handleErrorText("");
    }, 2000);
    return;
  }

  // Regex validation
  const regex = /^[\w\s!@#$%&*()_\-+=.,'":;?]+$/;
  if (!regex.test(inputValue)) {
    handleErrorText(
      "Input contains invalid characters. Allowed characters: ! @ # $ % & * ( ) _ - + = . , ' \" : ; ?",
    );
    setTimeout(() => {
      handleErrorText("");
    }, 2000);
    return;
  }

  if (buttonTexts[index] === "Change") {
    // Enable input and change button text to "Save"
    setDisabledInputs((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
    setButtonTexts((prevState) => {
      const newState = [...prevState];
      newState[index] = "Save";
      return newState;
    });
  } else if (buttonTexts[index] === "Save") {
    // Send data via fetch and after receiving response, change button text to "Saved"
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    try {
      const response = await fetch(URLs.USER_INFO_SAVE_PROFILE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ [field]: inputValue }), // Send the specific input value
      });

      if (response.ok) {
        setDisabledInputs((prevState) => {
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });

        // After a successful response, set button text to "Saved!"
        setButtonTexts((prevState) => {
          const newState = [...prevState];
          newState[index] = "Saved!";
          return newState;
        });
        setChangedUserName(inputValue);
        // Change the text back to "Change" after 2 seconds
        setTimeout(() => {
          setButtonTexts((prevState) => {
            const newState = [...prevState];
            newState[index] = "Change";
            return newState;
          });
          handleErrorText("");
        }, 2000);
      } else {
        if (response.status === 400) {
          response.json().then((data) => {
            handleErrorText(data.error.name[0].message);
          });
        } else {
          handleErrorText(response.statusText);
        }

        setButtonTexts((prevState) => {
          const newState = [...prevState];
          newState[index] = "Save";
          return newState;
        });
        setTimeout(() => {
          handleErrorText("");
        }, 2000);
      }
    } catch (error) {
      console.error("Error while saving data", error);
      handleErrorText("Error while saving data", error);
      setTimeout(() => {
        handleErrorText("");
      }, 2000);
    }
  }
};

export const handleInputChangeUserProfile = (field, event, setInputValues) => {
  setInputValues((prevValues) => ({
    ...prevValues,
    [field]: event.target.value,
  }));
};
export const handleButtonClickUserAddressHistory = (
  setUserAddressHistoryData,
) => {
  fetchUserData(1, setUserAddressHistoryData);
};

export const handleButtonClickDeleteUser = async () => {
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;

  try {
    await fetch(URLs.USER_PROFILE_DELETE_ACCOUNT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to delete account");
        }
      })
      .then((data) => {
        alert(data.message);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error("Error delete user", error);
  }
};
