import React from "react";
import { URLs } from "../utils/apis";

const BackToFreePlanWindow = ({ setBackToFreeWindow }) => {
  const handleClickBackToFreeWindowClose = () => {
    setBackToFreeWindow(false);
    document.body.style.overflow = "";
  };

  const handleClickBackToFree = async () => {
    try {
      const response = await fetch(URLs.PADDLE_CANCEL_URL);

      if (!response.ok) {
        console.log("error");
        setBackToFreeWindow(false);
        document.body.style.overflow = "";
      }

      window.location.reload();
    } catch (error) {
      console.log(error);
      setBackToFreeWindow(false);
      document.body.style.overflow = "";
    }
  };

  return (
    <div className="back-free-window">
      <div className="back-free-window__title">Cancel Your Subscription?</div>
      <div className="back-free-window__buttons rb-display-flex">
        <button
          className="button rb-btn rb-btn-white rb-display-flex rb-align-items-center"
          onClick={() => handleClickBackToFreeWindowClose()}
        >
          No
        </button>
        <button
          className="button rb-btn rb-btn-black rb-display-flex rb-align-items-center"
          onClick={() => handleClickBackToFree()}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default BackToFreePlanWindow;
