import React, { useEffect } from "react";
import PropTypes from "prop-types";
import GuaranteedData from "./GuaranteedData";
import VerifiableData from "./VerifiableData";
import CustomerReviews from "./CustomerReviews";
import Pricing from "./Pricing";
import SupplementaryInformation from "./SupplementaryInformation";
import TransformDataSecurity from "./TransformDataSecurity";

const Landing = ({ staticBasePath, landingSectionRef, isAuthenticated }) => {
  useEffect(() => {}, [landingSectionRef]);

  return (
    <>
      <div className="landing" ref={landingSectionRef}>
        <GuaranteedData staticBasePath={staticBasePath} />
        <VerifiableData staticBasePath={staticBasePath} />
        <CustomerReviews staticBasePath={staticBasePath} />
        <Pricing
          isAuthenticated={isAuthenticated}
          staticBasePath={staticBasePath}
        />
        <SupplementaryInformation staticBasePath={staticBasePath} />
        <TransformDataSecurity staticBasePath={staticBasePath} />
      </div>
    </>
  );
};

Landing.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  landingSectionRef: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
};
export default Landing;
