import React from "react";
import moment from 'moment';
const StampError = ({ error, staticBasePath, handleDefaultScreen }) => {
  return (
    <>
      <img
        src={`${staticBasePath}public/images/svg/vb-icon-not-verified.svg`}
        alt="Red checkmark"
        className="rb-result-img align-center"
      />
      <h2 className="rb-header rb-header-stamp rb-error-header rb-text-center">
        <span>ERROR:</span> No Stamp Made
      </h2>
      <div
        id="stamp-error"
        className="rb-container rb-stamp-window rb-column rb-align-center"
      >
        <div className="scalloped-top"></div>
        <div className="scalloped-bottom"></div>
        <div className="scalloped-left"></div>
        <div className="scalloped-right"></div>


        <div className="rb-error-text-wrap rb-details-table">
          <div className="rb-error-text">&lt;&lt;

            {error && typeof error === 'object' && error.error ? (
              <>{error.error}</>
            ) : (
              <>{error}</>
            )}
            &gt;&gt;</div>
          <div className="rb-error-text-contact">
            Please contact{" "}
            <a href="mailto:support@vbase.com">support@vbase.com</a> if this
            problem persists.
          </div>
        </div>
        {error && typeof error === 'object' && error.timestamp && (
          <div className="rb-error-timestamp">Timestamp: {moment(error.timestamp).format('YYYY-MM-DD HH:mm:ss')} +UTC</div>
        )}
      </div>
      <div className="rb-container">
        <button
          className="rb-btn rb-btn-click rb-btn-stamp-window rb-display-flex rb-justify-center"
          onClick={handleDefaultScreen}
        >
          Make Another Stamp
        </button>
      </div>
    </>
  );
};

export default StampError;
