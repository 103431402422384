import { URLs } from "./apis";
export const verifyHashWorker = async (
  hash,
  supportedHashAlgoMap,
  created = false,
  setIsLoading,
  setError,
  setStampData,
  setCurrentScreen,
  setButton,
  setLoading,
) => {
  try {
    setIsLoading(true);

    supportedHashAlgoMap = supportedHashAlgoMap || {};

    // Check if hash exists before adding
    if (!(hash in supportedHashAlgoMap)) {
      supportedHashAlgoMap[hash] = hash;
    }
    // Get list of hashes
    const objectHashes = Object.keys(supportedHashAlgoMap || {});

    const requestData = {
      "object-hashes": objectHashes,
      "filter-by-user": true,
    };
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;
    fetch(URLs.VERUFY_API_URL, {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.stamp_list) {
          setError(null);

          const lastElement = data.stamp_list[data.stamp_list.length - 1];
          //   lastElement.hashes = hashes;
          setStampData(lastElement);

          if (created) {
            setCurrentScreen("created");
          } else {
            const userStampsFound =
              data.stamp_list && data.stamp_list.length > 0;
            if (userStampsFound) {
              setCurrentScreen("found");
              setButton(true);
            } else {
              setButton(true);
            }
          }
        } else {
          setButton(true);
          setCurrentScreen("");
          console.log(data.message);
          if (data.message === "Invalid Content ID.") {
            setButton(true);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("An error occurred while verifying the stamp.");
        setLoading(false);
        setButton(true);
      });
  } catch (error) {
    console.error("Error in API request:", error);
    setButton(true);
  } finally {
    setIsLoading(false);
  }
};
