import React from "react";


const SubscriptionNotFound = ({staticBasePath}) => {

  return (


    <>
      <div className="rb-main-content rb-subscription-success">
        <img
          src={`${staticBasePath}public/images/svg/vb-icon-not-verified.svg`}
          alt="Green checkmark"
          className="rb-result-img align-center"
        />
        <h2 className="rb-header rb-header-stamp rb-text-center">
          Transaction not found
        </h2>
        <div className="rb-error-text-contact">
          Please contact{" "}
          <a href="mailto:support@vbase.com">support@vbase.com</a> if this
          problem persists.
        </div>
      </div>
    </>
  );
};

export default SubscriptionNotFound;
