import React, {useState} from "react";
import Cookies from 'js-cookie';
const NeedHelp = ({staticBasePath, linkDocs}) => {
  const [helpNeedIsActive, setHelpNeedIsActive] = useState(() => {
    const cookieValue = Cookies.get('helpNeedIsActive');
    return cookieValue !== undefined ? JSON.parse(cookieValue) : true;
  });

  const handleHelpNeedClick = () => {
    setHelpNeedIsActive(true);
    Cookies.set('helpNeedIsActive', JSON.stringify(true));
  };
  const handleHelpNeedClickClose = () => {
    setHelpNeedIsActive(false);
    Cookies.set('helpNeedIsActive', JSON.stringify(false));
  };
  return (
    <div className={`help__video
  ${helpNeedIsActive ? "active" : ""}`}>

        <img
          src={`${staticBasePath}public/images/svg/rb-need-help-close.svg`}
          alt="Close"
          className="help__video-close"
          onClick={handleHelpNeedClickClose}
        />
        <img
          src={`${staticBasePath}public/images/svg/rb-icon-need-help.svg`}
          alt="Help"
          className="help__video-icon"
          onClick={handleHelpNeedClick}
        />

        <div className="help__video-text">
          Need help?
        </div>
        <div className="help__video-link">
          See how-to
          <a target="_blank" href={linkDocs}>docs</a>
          or
          <a target="_blank" href="https://www.youtube.com/watch?v=Joh_zw0XoGM">video</a>
        </div>
      <a className="help__video-link help__video-link-small" target="_blank" href={linkDocs}>
        How-to docs
      </a>
      <a className="help__video-link help__video-link-small" target="_blank" href="https://www.youtube.com/watch?v=Joh_zw0XoGM">
        How-to video
      </a>
    </div>
  );
};

export default NeedHelp;
