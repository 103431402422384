import React from "react";

const LogoPDFSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.734" height="20.491" viewBox="0 0 15.734 20.491">
      <path
        d="M17.655,0H7.035C6.74,0,6.5.24,6.5.7V20.125a.551.551,0,0,0,.535.366H21.7a.551.551,0,0,0,.535-.366V4.749c0-.255-.034-.337-.094-.4L17.883.094A.323.323,0,0,0,17.655,0Z"
        transform="translate(-6.5 0)"/>
      <path d="M37.5.151V4.487h4.336Z" transform="translate(-26.157 -0.096)" fill="#d1d1d1"/>
      <path
        d="M18.524,20.842h0a.847.847,0,0,1-.51-.172,1.264,1.264,0,0,1-.588-1.183c.1-.859,1.158-1.758,3.157-2.673a35.409,35.409,0,0,0,2-5.671c-.526-1.146-1.038-2.632-.665-3.5a1.012,1.012,0,0,1,.6-.641,2.59,2.59,0,0,1,.536-.091c.266,0,.5.342.665.553s.509.619-.2,3.588A16.856,16.856,0,0,0,26.2,15.038a10.262,10.262,0,0,1,1.771-.189c.826,0,1.327.193,1.531.589a1.068,1.068,0,0,1-.206,1.139,1.4,1.4,0,0,1-1.171.628A3.507,3.507,0,0,1,25.906,16a25.275,25.275,0,0,0-4.657,1.489,14.967,14.967,0,0,1-1.257,2.242C19.452,20.489,18.985,20.842,18.524,20.842Zm1.4-2.7c-1.127.634-1.587,1.154-1.62,1.447-.005.049-.02.176.227.365C18.614,19.926,19.073,19.717,19.928,18.139ZM27.121,15.8c.43.331.535.5.816.5a.8.8,0,0,0,.638-.233.835.835,0,0,0,.121-.219c-.065-.034-.151-.1-.62-.1A7.714,7.714,0,0,0,27.121,15.8Zm-3.94-3.472a37.6,37.6,0,0,1-1.411,3.99A26.357,26.357,0,0,1,25.2,15.248,18.056,18.056,0,0,1,23.181,12.323Zm-.32-4.464a1.265,1.265,0,0,0,.051,1.7C23.412,8.44,22.884,7.852,22.861,7.86Z"
        transform="translate(-15.445 -3.526)" fill="#fff"/>
    </svg>
  );
};

export default LogoPDFSvg;


