import React from "react";

const HelpTriggerSvg = ({handleHelpTriggerClick}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="107" height="89" viewBox="0 0 97 79">
      <defs>
        <filter id="Path_14310" x="0" y="0" width="97" height="79" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood floodOpacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="Group_981" data-name="Group 981" transform="translate(-1338 -1004)">
        <g transform="matrix(1, 0, 0, 1, 1338, 1004)" filter="url(#Path_14310)">
          <g id="Path_14310-2" data-name="Path 14310" transform="translate(9 6)" fill="#fff">
            <path d="M4,0H75a4,4,0,0,1,4,4V53a4,4,0,0,1-4,4H43l-3.546,4-4.283-4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
                  stroke="none"/>
            <path
              d="M 4 1 C 2.345787048339844 1 1 2.345787048339844 1 4 L 1 53 C 1 54.65421295166016 2.345787048339844 56 4 56 L 35.5704460144043 56 L 39.39143371582031 59.56865692138672 L 42.55500030517578 56 L 75 56 C 76.65421295166016 56 78 54.65421295166016 78 53 L 78 4 C 78 2.345787048339844 76.65421295166016 1 75 1 L 4 1 M 4 0 L 75 0 C 77.20913696289062 0 79 1.790855407714844 79 4 L 79 53 C 79 55.20914459228516 77.20913696289062 57 75 57 L 43.00487899780273 57 L 39.45892333984375 61 L 35.17609024047852 57 L 4 57 C 1.790863037109375 57 0 55.20914459228516 0 53 L 0 4 C 0 1.790855407714844 1.790863037109375 0 4 0 Z"
              stroke="none" fill="rgba(112,112,112,0.2)"/>
          </g>
        </g>
        <g id="Group_980" onClick={handleHelpTriggerClick} data-name="Group 980" transform="translate(-11 -10)">
          <g id="Path_14309" data-name="Path 14309" transform="translate(1370 1047)" fill="rgba(237,240,229,0.4)">
            <path d="M16.5,0h23a11.5,11.5,0,0,1,0,23h-23a11.5,11.5,0,0,1,0-23Z" stroke="none"/>
            <path
              d="M 16.5 1 C 10.71028137207031 1 6 5.71027946472168 6 11.5 C 6 17.28972053527832 10.71028137207031 22 16.5 22 L 39.5 22 C 45.28971862792969 22 50 17.28972053527832 50 11.5 C 50 5.71027946472168 45.28971862792969 1 39.5 1 L 16.5 1 M 16.5 0 L 39.5 0 C 45.85128021240234 0 51 5.14872932434082 51 11.5 C 51 17.85127067565918 45.85128021240234 23 39.5 23 L 16.5 23 C 10.14873123168945 23 5 17.85127067565918 5 11.5 C 5 5.14872932434082 10.14873123168945 0 16.5 0 Z"
              stroke="none" fill="rgba(112,112,112,0.2)"/>
          </g>
          <rect id="Rectangle_582" data-name="Rectangle 582" width="19" height="19" rx="9.5"/>
        </g>
      </g>
    </svg>
  );
};

export default HelpTriggerSvg;
