import React, { useState, useEffect } from "react";
import moment from "moment";
import BillingHistory from "./BillingHistory";
import { URLs } from "../../utils/apis";
const Billing = () => {
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const [canclelShow, setCanclelShow] = useState(false);
  const [canclelMessage, setCanclelMessage] = useState(false);

  const formattedDateTime = (date) =>
    moment(date, "YYYY-MM-DD hh:mm:ss").format("MMMM DD, YYYY");

  const [billingHistoryShow, setBillingHistoryShow] = useState(false);
  const handleClickBillingHistory = () => {
    setBillingHistoryShow(true);
  };

  const handleClickShowCancel = () => {
    setCanclelShow(!canclelShow);
  };

  const handleClickHideCancel = () => {
    setCanclelShow(false);
  };

  const handleClickCancel = async () => {
    setCanclelShow(false);
    try {
      const response = await fetch(URLs.PADDLE_CANCEL_URL);

      const data = await response.json();
      // Check if the status is 400
      if (response.status === 400) {
        setCanclelMessage(data.message);
        return; // Stop further execution if status is 400
      }

      if (!response.ok) {
        setCanclelMessage("Error");
      }

      setCanclelMessage(data.message);

      // Delay the page reload by 3 seconds
      setTimeout(() => {
        window.location.reload(); // This will refresh the page after 3 seconds
      }, 3000);
    } catch (error) {
      setCanclelMessage(error);
    }
  };

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    subscription_info: {
      is_subscription_active: false,
      is_subscription_paused: false,
      is_subscription_cancelled: false,
      is_subscription_to_be_cancelled: false,
      is_subscription_to_be_paused: false,
      subscription_started_at: false,
      next_billed_at: false,
      paused_at: false,
      canceled_at: false,
      subscription_type: false,
    },
    payment_info: {
      payment_type: false,
      card_type: false,
      last4: false,
      verbose_type: false,
    },
    billing_info: {
      billed_to: false,
      email: false,
      vat_number: false,
    },
    user_balance_info: {
      expirable_credits: "",
      paid_credits: "",
      total_credits: "",
    },
    subscription_actions: {
      cancel: false,
    },
    subscription_plan: {
      title: false,
      description: false,
    },
  });

  // Fetch user data from API
  useEffect(() => {
    const paddleData = async () => {
      try {
        const response = await fetch(URLs.PADDLE_DETAILS_URL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setInputValues({
          subscription_info: {
            is_subscription_active:
              data[0]?.subscription_info?.is_subscription_active || false,
            is_subscription_paused:
              data[0]?.subscription_info?.is_subscription_paused || false,
            is_subscription_cancelled:
              data[0]?.subscription_info?.is_subscription_cancelled || false,
            is_subscription_to_be_cancelled:
              data[0]?.subscription_info?.is_subscription_to_be_cancelled ||
              false,
            is_subscription_to_be_paused:
              data[0]?.subscription_info?.is_subscription_to_be_paused || false,
            subscription_started_at:
              data[0]?.subscription_info?.subscription_started_at || false,
            next_billed_at: data[0]?.subscription_info?.next_billed_at || false,
            paused_at: data[0]?.subscription_info?.paused_at || false,
            canceled_at: data[0]?.subscription_info?.canceled_at || false,
            subscription_type:
              data[0]?.subscription_info?.subscription_type || false,
          },
          payment_info: {
            payment_type: data[0]?.payment_info?.payment_type || false,
            card_type: data[0]?.payment_info?.card_type || false,
            last4: data[0]?.payment_info?.last4 || false,
            verbose_type: data[0]?.payment_info?.verbose_type || false,
          },
          billing_info: {
            billed_to: data[0]?.billing_info?.billed_to || false,
            email: data[0]?.billing_info?.email || false,
            vat_number: data[0]?.billing_info?.vat_number || false,
          },
          user_balance_info: {
            expirable_credits:
              data[0]?.user_balance_info?.expirable_credits || 0,
            paid_credits: data[0]?.user_balance_info?.paid_credits || 0,
            total_credits: data[0]?.user_balance_info?.total_credits || 0,
          },
          subscription_actions: {
            cancel: data[0]?.subscription_actions?.cancel || false,
          },
          subscription_plan: {
            title: data[0]?.subscription_plan?.title || false,
            description: data[0]?.subscription_plan?.description || false,
          },
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    paddleData();
  }, []); // Empty dependency array means this effect runs once on mount
  if (isLoading) {
    return (
      <div className="rb-display-flex rb-justify-content-center">
        Loading...
      </div>
    ); // Show a loading message while data is being fetched
  }
  return (
    <>
      {billingHistoryShow ? (
        <>
          <BillingHistory setBillingHistoryShow={setBillingHistoryShow} />
        </>
      ) : (
        <div className="user-profile-tabs-content-item billing">
          <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
            <h2>Subscription Info</h2>
          </div>
          {inputValues["subscription_plan"]["title"] && (
            <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
              <label htmlFor="display-name">Current Subscription Plan:</label>
              <input
                id="display-name"
                className="rb-input disabled"
                value={inputValues["subscription_plan"]["title"]}
                disabled="disabled"
              />
              <button></button>
            </div>
          )}
          {inputValues["subscription_info"]["is_subscription_active"] && (
            <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
              <label htmlFor="display-name">Subscription Active:</label>
              <input
                id="display-name"
                className="rb-input disabled"
                value={
                  inputValues["subscription_info"]["is_subscription_active"]
                    ? "Yes"
                    : "No"
                }
                disabled="disabled"
              />
              <button></button>
            </div>
          )}

          {inputValues["subscription_info"]["subscription_type"] && (
            <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
              <label htmlFor="display-name">Subscription Type:</label>
              <input
                id="display-name"
                className="rb-input disabled"
                value={inputValues["subscription_info"]["subscription_type"]}
                disabled="disabled"
              />
              <button></button>
            </div>
          )}
          {inputValues["subscription_info"]["is_subscription_paused"] ? (
            <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
              <label htmlFor="display-name">Subscription Paused:</label>
              <input
                id="display-name"
                className="rb-input disabled"
                value={
                  inputValues["subscription_info"]["is_subscription_paused"]
                    ? "Yes"
                    : "No"
                }
                disabled="disabled"
              />
              <button></button>
            </div>
          ) : inputValues["subscription_info"]["is_subscription_cancelled"] ? (
            <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
              <label htmlFor="display-name">Subscription Cancelled:</label>
              <input
                id="display-name"
                className="rb-input disabled"
                value={
                  inputValues["subscription_info"]["is_subscription_cancelled"]
                    ? "Yes"
                    : "No"
                }
                disabled="disabled"
              />
              <button></button>
            </div>
          ) : (
            <></>
          )}
          {inputValues["subscription_info"]["subscription_started_at"] && (
            <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
              <label htmlFor="display-name">Subscription Start Date:</label>
              <input
                id="display-name"
                className="rb-input disabled"
                value={formattedDateTime(
                  inputValues["subscription_info"]["subscription_started_at"],
                )}
                disabled="disabled"
              />
              <button></button>
            </div>
          )}

          {inputValues["subscription_info"]["next_billed_at"] && (
            <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
              <label htmlFor="display-name">Next Billing Date:</label>
              <input
                id="display-name"
                className="rb-input disabled"
                value={formattedDateTime(
                  inputValues["subscription_info"]["next_billed_at"],
                )}
                disabled="disabled"
              />
              <button></button>
            </div>
          )}

          {inputValues["subscription_info"]["paused_at"] && (
            <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
              <label htmlFor="display-name">Paused At:</label>
              <input
                id="display-name"
                className="rb-input disabled"
                value={formattedDateTime(
                  inputValues["subscription_info"]["paused_at"],
                )}
                disabled="disabled"
              />
              <button></button>
            </div>
          )}

          {inputValues["subscription_info"]["canceled_at"] && (
            <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
              <label htmlFor="display-name">Canceled At:</label>
              <input
                id="display-name"
                className="rb-input disabled"
                value={formattedDateTime(
                  inputValues["subscription_info"]["canceled_at"],
                )}
                disabled="disabled"
              />
              <button></button>
            </div>
          )}

          {inputValues["payment_info"]["payment_type"] === "card" ? (
            <>
              <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
                <h2>Payment Info</h2>
              </div>
              {inputValues["payment_info"]["card_type"] && (
                <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
                  <label htmlFor="display-name">Card Type:</label>
                  <input
                    id="display-name"
                    className="rb-input disabled"
                    value={inputValues["payment_info"]["card_type"]}
                    disabled="disabled"
                  />
                  <button></button>
                </div>
              )}
              {inputValues["payment_info"]["last4"] && (
                <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
                  <label htmlFor="display-name">Last 4:</label>
                  <input
                    id="display-name"
                    className="rb-input disabled"
                    value={inputValues["payment_info"]["last4"]}
                    disabled="disabled"
                  />
                  <button></button>
                </div>
              )}
            </>
          ) : (
            <>
              {inputValues["payment_info"]["payment_type"] && (
                <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
                  <label htmlFor="display-name">Payment Type:</label>
                  <input
                    id="display-name"
                    className="rb-input disabled"
                    value={inputValues["payment_info"]["payment_type"]}
                    disabled="disabled"
                  />
                  <button></button>
                </div>
              )}
              {inputValues["payment_info"]["verbose_type"] && (
                <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
                  <label htmlFor="display-name">Verbose Type:</label>
                  <input
                    id="display-name"
                    className="rb-input disabled"
                    value={inputValues["payment_info"]["verbose_type"]}
                    disabled="disabled"
                  />
                  <button></button>
                </div>
              )}
            </>
          )}

          {(inputValues["billing_info"]["billed_to"] ||
            inputValues["billing_info"]["email"] ||
            inputValues["billing_info"]["vat_number"]) && (
            <>
              <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
                <h2>Billing Info</h2>
              </div>
              {inputValues["billing_info"]["billed_to"] && (
                <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
                  <label htmlFor="display-name">Billed To:</label>
                  <input
                    id="display-name"
                    className="rb-input disabled"
                    value={inputValues["billing_info"]["billed_to"]}
                    disabled="disabled"
                  />
                  <button></button>
                </div>
              )}
              {inputValues["billing_info"]["email"] && (
                <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
                  <label htmlFor="display-name">Email:</label>
                  <input
                    id="display-name"
                    className="rb-input disabled"
                    value={inputValues["billing_info"]["email"]}
                    disabled="disabled"
                  />
                  <button></button>
                </div>
              )}
              {inputValues["billing_info"]["vat_number"] && (
                <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
                  <label htmlFor="display-name">Vat Number:</label>
                  <input
                    id="display-name"
                    className="rb-input disabled"
                    value={inputValues["billing_info"]["vat_number"]}
                    disabled="disabled"
                  />
                  <button></button>
                </div>
              )}
            </>
          )}

          <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
            <h2>Balance Info</h2>
          </div>

          <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
            <label htmlFor="display-name">Free Stamp Credits:</label>
            <input
              id="display-name"
              className="rb-input disabled"
              value={inputValues["user_balance_info"]["expirable_credits"]}
              disabled="disabled"
            />
            <button></button>
          </div>

          <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
            <label htmlFor="display-name">Purchased Stamp Credits:</label>
            <input
              id="display-name"
              className="rb-input disabled"
              value={inputValues["user_balance_info"]["paid_credits"]}
              disabled="disabled"
            />
            <button></button>
          </div>

          <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
            <label htmlFor="display-name">Total Stamp Credits:</label>
            <input
              id="display-name"
              className="rb-input disabled"
              value={inputValues["user_balance_info"]["total_credits"]}
              disabled="disabled"
            />
            <button></button>
          </div>

          <div className="user-profile-line user-profile-line-link rb-display-flex rb-justify-content-center rb-align-items-center">
            <label></label>
            <a href="/profile/payment">Change Plan</a>
          </div>

          <div className="user-profile-line user-profile-line-link rb-display-flex rb-justify-content-center rb-align-items-center">
            <label></label>
            <button onClick={() => handleClickBillingHistory()}>
              Billing History
            </button>
          </div>
          {inputValues["subscription_info"]["is_subscription_active"] && (
            <>
              <div className="user-profile-line user-profile-line-link user-profile-line user-profile-line-cancel rb-display-flex rb-justify-content-center rb-align-items-center">
                <label></label>
                <button onClick={() => handleClickShowCancel()}>
                  Cancel Subscription
                </button>
              </div>

              {canclelShow && (
                <div className="user-profile-line user-profile-line-link user-profile-line user-profile-line-cancel-choice rb-display-flex rb-justify-content-center rb-align-items-center">
                  <label></label>
                  <button onClick={() => handleClickHideCancel()}>No</button>
                  <button onClick={() => handleClickCancel()}>Yes</button>
                </div>
              )}

              {canclelMessage && (
                <div className="user-profile-line user-profile-line-link user-profile-line user-profile-line-cancel-message rb-display-flex rb-justify-content-center rb-align-items-center">
                  <label>{canclelMessage}</label>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Billing;
