import PropTypes from "prop-types";
import React from "react";
import { handleClickToCopy } from "../utils/copyToClipboard";
import Info from "./Info";
import Help from "./Help";
import Notification from "./Notification";
import QuotaLimit100 from "./quota/QuotaLimit100";

import CollectionSelect from "./CollectionSelect";

const StampFromFile = ({
  currentScreen,
  staticBasePath,
  objectHash,
  handleFileInput,
  isAuthenticated,
  isDisabled,
  helpIsActive,
  quotaUsed100,
  onSelectedOptionCollection,
  selectedOptionCollection,
  activateSelectCollection,
  onActivateSelectCollection,
  selectCollections,
}) => {
  const hashStampClassName = `rb-hash rb-position-absolute rb-display-flex rb-align-items-center rb-flex-wrap-wrap ${
    !objectHash ? "rb-hidden" : ""
  }`;

  return (
    <div id="verify-method-file__screen" className="rb-method-file__screen">
      <div className="rb-method">
        {currentScreen === "found" && (
          <div className="rb-container">
            <Info
              textInfo="You have already stamped this file previously. You can choose another file or stamp this file again."
              staticBasePath={staticBasePath}
            />
          </div>
        )}
        <div
          id="drop-area"
          className={`rb-area rb-position-relative ${
            isAuthenticated ? "" : "rb-area-disabled"
          } ${!quotaUsed100 ? "" : "rb-area-disabled"} ${
            objectHash && currentScreen !== "found" ? "" : "rb-area-no-hash"
          }`}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleFileInput}
        >
          {!isAuthenticated && <Notification />}
          {quotaUsed100 && <QuotaLimit100 />}

          {helpIsActive && (
            <Help
              position="top-right"
              text="Selecting a file calculates its digital fingerprint (aka: ContentID or SHA3 hash). This calculation is done inside your browser, the file is never transmitted anywhere outside your computer."
              link=""
            />
          )}
          {objectHash && currentScreen !== "found" && (
            <div className="rb-file-loaded">
              <img
                src={`${staticBasePath}public/images/svg/rb_file_uploaded.svg`}
                alt="Uploaded"
              />
              <span>File Ready.</span>
            </div>
          )}
          <a
            href="#"
            id="fileOpenTrigger"
            className={`rb-btn rb-btn-click ${
              isAuthenticated ? "" : "rb-btn-disabled"
            } ${!quotaUsed100 ? "" : "rb-btn-disabled"} ${
              objectHash && currentScreen !== "found"
                ? "rb-btn-click__file-hash"
                : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              if (isAuthenticated && !quotaUsed100) {
                document.getElementById("fileInput").click();
              }
            }}
          >
            <div className="rb-btn-click-file">
              {isDisabled && (
                <img
                  src={`${staticBasePath}public/images/svg/vb-icon-file-browse_v1.svg`}
                  alt="Info"
                />
              )}
              <p>
                {isDisabled ? "Browse for file" : "Browse for a different file"}
              </p>
            </div>
          </a>
          <div className="rb-area__text">
            {isDisabled
              ? "Or just drag and drop file here"
              : "or drag and drop here"}
          </div>

          <div className={hashStampClassName}>
            <div className="rb-hash__text"> File Content ID:</div>
            <div className="rb-hash__content">
              {objectHash}
              <img
                className="rb-details-table__copy"
                src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                alt="copy"
                onClick={() => handleClickToCopy(objectHash)}
              />
            </div>
          </div>
        </div>

        {selectCollections && isAuthenticated && (
          <CollectionSelect
            activateSelectCollection={activateSelectCollection}
            onActivateSelectCollection={onActivateSelectCollection}
            selectCollections={selectCollections}
            onSelectedOptionCollection={onSelectedOptionCollection}
            selectedOptionCollection={selectedOptionCollection}
            helpIsActive={helpIsActive}
          />
        )}

        <div className="rb-info">
          <img
            src={`${staticBasePath}public/images/svg/private_local.svg`}
            alt="Info"
          />
          The file is processed locally on your computer. It is never sent to
          vBase or anywhere else.
          {helpIsActive && (
            <Help
              position="right"
              text="It’s your data, and privacy is important. The vBase Stamper works without transmitting your data."
              link=""
            />
          )}
        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileInput}
        />
      </div>
      <div></div>
    </div>
  );
};

StampFromFile.propTypes = {
  currentScreen: PropTypes.string.isRequired,
  staticBasePath: PropTypes.string.isRequired,
  objectHash: PropTypes.string,
  handleFileInput: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  helpIsActive: PropTypes.bool.isRequired,
  quotaUsed100: PropTypes.bool.isRequired,
  onSelectedOptionCollection: PropTypes.func.isRequired,
  selectedOptionCollection: PropTypes.string,
  activateSelectCollection: PropTypes.bool.isRequired,
  onActivateSelectCollection: PropTypes.func,
  selectCollections: PropTypes.array,
};
export default StampFromFile;
