import React from "react";
import PropTypes from "prop-types";
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const delta = 1;

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const left = Math.max(2, currentPage - delta);
    const right = Math.min(totalPages - 1, currentPage + delta);

    // Always show the first page
    pageNumbers.push(1);

    // Add ellipsis if needed
    if (left > 2) {
      pageNumbers.push("...");
    }

    // Generate the pages around the current page
    for (let i = left; i <= right; i++) {
      pageNumbers.push(i);
    }

    // Add ellipsis if needed
    if (right < totalPages - 1) {
      pageNumbers.push("...");
    }

    // Always show the last page
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div className="pagination-controls rb-display-flex rb-justify-content-center">
      <button
        className={`pagination-controls-item pagination-controls-first ${
          currentPage === 1 ? "pagination-controls-disabled" : ""
        }`}
        onClick={() => currentPage > 1 && onPageChange(1)}
        disabled={currentPage === 1}
      >
        First
      </button>

      <button
        className={`pagination-controls-item pagination-controls-previous ${
          currentPage === 1 ? "pagination-controls-disabled" : ""
        }`}
        onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>

      {/* Render Page Numbers */}
      {generatePageNumbers().map((page, index) => (
        <button
          key={index}
          onClick={() => page !== "..." && onPageChange(page)}
          disabled={page === currentPage}
          className={`pagination-controls-item ${
            page === currentPage ? "pagination-controls-active" : ""
          }`}
        >
          {page}
        </button>
      ))}

      <button
        className={`pagination-controls-item pagination-controls-next ${
          currentPage === totalPages ? "pagination-controls-disabled" : ""
        }`}
        onClick={() =>
          currentPage < totalPages && onPageChange(currentPage + 1)
        }
        disabled={currentPage === totalPages}
      >
        Next
      </button>

      <button
        className={`pagination-controls-item pagination-controls-last ${
          currentPage === totalPages ? "pagination-controls-disabled" : ""
        }`}
        onClick={() => currentPage < totalPages && onPageChange(totalPages)}
        disabled={currentPage === totalPages}
      >
        Last
      </button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired, // Number type for currentPage
  totalPages: PropTypes.number.isRequired, // Number type for totalPages
  onPageChange: PropTypes.func.isRequired, // Function type for onPageChange
};
export default Pagination;
