import React from "react";
import Help from "./Help";

const VerifyFile = ({
                      handleFileInput,
                      staticBasePath,
                      helpIsActive
                    }) => {

  return (
    <div
      id="verify-method-file__screen"
      className="rb-method-file__screen"
    >
      <div className="rb-method">
        <div
          id="drop-area"
          className="rb-area rb-area-no-hash rb-position-relative"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleFileInput}
        >
          {helpIsActive && (
            <Help position="top-right" text="Selecting a file calculates its digital fingerprint (aka: ContentID or SHA3 hash). This calculation is done inside your browser, the file is never transmitted anywhere outside your computer." link="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper" />
          )}
          <a
            href="#"
            id="fileOpenTrigger"
            className="rb-btn rb-btn-click"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("fileInput").click();
            }}
          >
            <div className="rb-btn-click-file">
              <img
                src={`${staticBasePath}public/images/svg/vb-icon-file-browse_v1.svg`}
                alt="Info"
              />
              <p>Browse for file</p>
            </div>
          </a>
          <div className="rb-area__text">
            Or just drag and drop file here
          </div>
        </div>
        <div className="rb-info">
          <img
            src={`${staticBasePath}public/images/svg/private_local.svg`}
            alt="Info"
          />
          The file is processed locally on your computer. It is never sent to vBase or anywhere else.
          {helpIsActive && (
            <Help position="right"
                  text="It’s your data, and privacy is important. The vBase Stamper works without transmitting your data."
                  link=""/>
          )}
        </div>
        <input
          type="file"
          id="fileInput"
          style={{display: "none"}}
          onChange={handleFileInput}
        />
      </div>
    </div>
  );
};

export default VerifyFile;
