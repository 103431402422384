import React, {useEffect} from 'react';

const LearnMore = ({staticBasePath, landingSectionRef}) => {

    const handleLearnMoreClick = () => {

      if (landingSectionRef.current) {
        const offsetTop = landingSectionRef.current.getBoundingClientRect().top + window.pageYOffset;
        setTimeout(() => {
          window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
          });
        }, 100);
      }

    };

  return (
    <div className="learn-more" onClick={handleLearnMoreClick}>
      <span>Learn More</span>
      <img
        src={`${staticBasePath}public/images/svg/arrow_down.svg`}
        alt="Scroll down"
      />
    </div>
  );
};

export default LearnMore;
