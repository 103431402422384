import React from "react";

const LogoVBSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.848" height="17.697" viewBox="0 0 13.848 17.697">
      <path
        d="M11.528,8.867a52.52,52.52,0,0,0-3.286,5.819.617.617,0,0,1-.544.355L6.6,15.069a.457.457,0,0,1-.341-.142c-.53-.568-2.722-2.8-3.948-3.953A.262.262,0,0,1,2.3,10.6a1.649,1.649,0,0,1,2.628-.052c.63.634,1.534,1.482,2.05,2.022a62.58,62.58,0,0,1,5.227-8.347,1.639,1.639,0,0,0,.1-1.889,4.183,4.183,0,0,0-1.037-1.094A8.006,8.006,0,0,0,6.415-.01H.639A.65.65,0,0,0,0,.634V16.939a.75.75,0,0,0,.748.748H6.922a8.814,8.814,0,0,0,5.184-1.264,4.126,4.126,0,0,0,1.742-3.541,4.532,4.532,0,0,0-.7-2.58,4.416,4.416,0,0,0-1.624-1.439Z"
        transform="translate(0 0.01)"/>
    </svg>
  );
};

export default LogoVBSvg;


