import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { PDFDocument, StandardFonts, rgb, PDFName, PDFString } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import QRCode from "qrcode";
import { formatCollectionUserFoundPDF } from "../utils/formatCollectionUserFound";
import { formatStampDetails } from "../utils/formatStampDetails";

const DownloadPDF = ({ data, formattedDateTime, allHashes }) => {
  // Custom font for pdf
  const HelveticaLightRegular =
    "/static/assets/fonts/Helvetica_Light_Regular.otf";

  const LatoLight = "/static/assets/fonts/latolight.ttf";

  const {
    user_id,
    user,
    collection_name,
    collection_hash,
    objectCid,
    blockExplorerUrl,
    transactionHash,
    chainId,
    blockchainName,
  } = data;

  let collectionName;

  let enableCollectionFullName = false;
  let collectionFullName;

  if (collection_name && collection_name !== "n/a") {
    collectionName = formatCollectionUserFoundPDF(collection_name, 20, 30, 53);
    if (collection_name.length > 53) {
      enableCollectionFullName = true;
      collectionFullName = collection_name;
    }
  } else {
    if (collection_hash && collection_hash !== "n/a") {
      collectionName = "<<Not Available>>";
    } else {
      collectionName = "<<None>>";
    }
  }

  let collectionHash;

  if (collection_hash && collection_hash !== "n/a") {
    collectionHash = collection_hash;
  } else {
    collectionHash = "<<None>>";
  }

  //Link for QR code
  const qrLink = "https://app.vbase.com/verify/?cid=" + objectCid;

  const generatePDF = useCallback(async () => {
    // Load the PDF template
    const templatePDFUrl = "/static/assets/pdf/template_stamp.pdf"; // Path to the PDF template
    const response = await fetch(templatePDFUrl);
    const templateBytes = await response.arrayBuffer(); // Get template data as ArrayBuffer

    const fontBytes = await fetch(HelveticaLightRegular).then((res) =>
      res.arrayBuffer(),
    );

    const fontBytesLato = await fetch(LatoLight).then((res) =>
      res.arrayBuffer(),
    );

    // Load the PDF document from the template
    const pdfDoc = await PDFDocument.load(templateBytes);

    // Register fontkit
    pdfDoc.registerFontkit(fontkit);

    // Custom font for the PDF
    const helveticaLightFont = await pdfDoc.embedFont(fontBytes);

    // Custom font for the PDF
    const LatoLightFont = await pdfDoc.embedFont(fontBytesLato);

    // Standard font for the PDF
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Get the first page of the PDF
    const page = pdfDoc.getPages()[0];
    const secondPage = pdfDoc.getPages()[1];
    const { width, height } = page.getSize();

    // User
    page.drawText(user, {
      x: 295, // X coordinate
      y: height - 226, // Y coordinate (from top to bottom)
      size: 18,
      font: font,
      color: rgb(0.2, 0.2, 0.2),
    });

    // Collection Name
    page.drawText(collectionName, {
      x: 350, // X coordinate
      y: height - 267, // Y coordinate (from top to bottom)
      size: 18,
      font: font,
      color: rgb(0.2, 0.2, 0.2),
    });

    //Timestamp
    page.drawText(formattedDateTime + " +UTC", {
      x: 300, // X coordinate
      y: height - 307, // Y coordinate (from top to bottom)
      size: 18,
      font: font,
      color: rgb(0.2, 0.2, 0.2),
    });

    // Blockchain Name
    page.drawText(blockchainName, {
      x: 380, // X coordinate
      y: height - 348, // Y coordinate (from top to bottom)
      size: 18,
      font: font,
      color: rgb(0.2, 0.2, 0.2),
    });

    // User ID
    page.drawText(user_id, {
      x: 240,
      y: height - 899,
      size: 13,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // Content ID
    page.drawText(objectCid, {
      x: 240,
      y: height - 923,
      size: 13,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // Collection hash
    page.drawText(collectionHash, {
      x: 240,
      y: height - 948,
      size: 13,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // blockchain Name and blockchain ID
    page.drawText(blockchainName + " (" + chainId + ")", {
      x: 240,
      y: height - 974,
      size: 13,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // Transaction ID
    page.drawText(transactionHash, {
      x: 240,
      y: height - 999,
      size: 13,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // blockExplorerUrl link in PDF
    const linkBlockchain = blockExplorerUrl + transactionHash;

    page.drawText("3rd Party Blockchain Scanner", {
      x: 240,
      y: height - 1024,
      size: 13,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // Draw the underline by calculating the position and width based on the text
    const textWidth = helveticaLightFont.widthOfTextAtSize(
      "3rd Party Blockchain Scanner",
      13,
    ); // Get the width of the text
    const underlineY = height - 1028; // Y coordinate of the underline (slightly below the text)

    page.drawLine({
      start: { x: 240, y: underlineY },
      end: { x: 240 + textWidth, y: underlineY },
      thickness: 1, // Thickness of the underline
      color: rgb(0, 0, 0), // Color of the underline (same as text color)
    });

    const createPageLinkAnnotation = (page, uri) =>
      page.doc.context.register(
        page.doc.context.obj({
          Type: "Annot",
          Subtype: "Link",

          Rect: [240, height - 1032, 240 + textWidth, height - 1012], // Area for the link
          // Border: [0, 0, 2],       // Border size
          // C: [0, 0, 1],            // Border color (blue)
          A: {
            Type: "Action",
            S: "URI",
            URI: PDFString.of(uri), // URI to open when clicked
          },
        }),
      );

    // Create the link annotation
    const linkBlockchainAnnotation = createPageLinkAnnotation(
      page,
      linkBlockchain,
    );

    // Get the existing annotations (if any)
    const existingAnnotsBlockchain = page.node.get(PDFName.of("Annots"));

    // If there are existing annotations, append the new one
    let annotsArrayBlockchain = existingAnnotsBlockchain
      ? existingAnnotsBlockchain.asArray()
      : [];
    annotsArrayBlockchain.push(linkBlockchainAnnotation);

    // Set the updated annotations array back to the page
    page.node.set(
      PDFName.of("Annots"),
      pdfDoc.context.obj(annotsArrayBlockchain),
    );

    if (allHashes[objectCid]) {
      // Hashing algorithm used key
      page.drawText("Hashing algorithm used:", {
        x: 51,
        y: height - 1048,
        size: 15,
        font: font,
        color: rgb(0, 0, 0),
      });

      // Hashing algorithm used value
      page.drawText(allHashes[objectCid], {
        x: 238,
        y: height - 1048,
        size: 15,
        font: helveticaLightFont,
        color: rgb(0, 0, 0),
      });
    }

    const drawMultilineText = (page, text, options) => {
      const {
        x,
        y,
        size,
        font,
        color,
        maxWidth, // Maximum width of the text
        lineHeight, // Line height for spacing between lines
      } = options;

      // Split the text into lines
      const words = text.split(" ");
      let line = "";
      let lines = [];

      for (const word of words) {
        const testLine = line ? `${line} ${word}` : word;
        const lineWidth = font.widthOfTextAtSize(testLine, size);

        // Check if the line exceeds the maximum width
        if (lineWidth > maxWidth && line) {
          lines.push(line); // Save the current line
          line = word; // Start a new line with the current word
        } else {
          line = testLine; // Add the word to the current line
        }
      }
      lines.push(line); // Add the last line

      // Render each line on the page
      lines.forEach((line, index) => {
        page.drawText(line, {
          x: x,
          y: y - index * lineHeight, // Offset each subsequent line vertically
          size: size,
          font: font,
          color: color,
        });
      });
    };

    if (enableCollectionFullName) {
      const maxWidth = 630; // Maximum width of the text in points
      const lineHeight = 16; // Line height for spacing between lines
      let heightTop;
      if (allHashes[objectCid]) {
        heightTop = 1071;
      } else {
        heightTop = 1048;
      }
      // Draw the label for the collection name
      page.drawText("Collection Name:", {
        x: 51,
        y: height - heightTop,
        size: 15,
        font: font,
        color: rgb(0, 0, 0),
      });

      // Draw the collection name as multiline text
      drawMultilineText(page, collectionFullName, {
        x: 238,
        y: height - heightTop,
        size: 14,
        font: helveticaLightFont,
        color: rgb(0, 0, 0),
        maxWidth: maxWidth,
        lineHeight: lineHeight,
      });
    }

    // Link app.vbase.com/verify?cid=...
    const linkVerify = "app.vbase.com/verify?cid=" + objectCid;
    const textVerify = "Visit vBase Verify";

    secondPage.drawText(textVerify, {
      x: 65,
      y: height - 439,
      size: 16,
      font: LatoLightFont,
      color: rgb(0, 0, 0),
    });

    // Draw the underline by calculating the position and width based on the text
    const textWidthVerify =
      LatoLightFont.widthOfTextAtSize(textVerify, 16) + 65; // Get the width of the text
    const underlineYVerify = height - 443; // Y coordinate of the underline (slightly below the text)

    secondPage.drawLine({
      start: { x: 99, y: underlineYVerify },
      end: { x: textWidthVerify, y: underlineYVerify },
      thickness: 1, // Thickness of the underline
      color: rgb(0, 0, 0), // Color of the underline (same as text color)
    });

    const createPageLinkAnnotationVerify = (secondPage, uri) =>
      secondPage.doc.context.register(
        secondPage.doc.context.obj({
          Type: "Annot",
          Subtype: "Link",

          Rect: [99, height - 444, textWidthVerify, height - 424], // Area for the link
          // Border: [0, 0, 2],       // Border size
          // C: [0, 0, 1],            // Border color (blue)
          A: {
            Type: "Action",
            S: "URI",
            URI: PDFString.of(uri), // URI to open when clicked
          },
        }),
      );

    // Create the link annotation
    const linkVerifyAnnotation = createPageLinkAnnotationVerify(
      secondPage,
      "https://" + linkVerify,
    );

    // Get the existing annotations (if any)
    const existingAnnotsVerifyAnnotation = secondPage.node.get(
      PDFName.of("Annots"),
    );

    // If there are existing annotations, append the new one
    let annotsArrayVerifyAnnotation = existingAnnotsVerifyAnnotation
      ? existingAnnotsVerifyAnnotation.asArray()
      : [];
    annotsArrayVerifyAnnotation.push(linkVerifyAnnotation);

    // Set the updated annotations array back to the page
    secondPage.node.set(
      PDFName.of("Annots"),
      pdfDoc.context.obj(annotsArrayVerifyAnnotation),
    );

    // Text hash type
    let textHashType;
    let textHashTypeRect;
    let textHashTypeBorderPositionStart;
    let textHashTypeBorderPositionEnd;
    if (allHashes[objectCid]) {
      textHashType = allHashes[objectCid];
      if (allHashes[objectCid] === "Keccak-256") {
        textHashTypeRect = [506, height - 525, 353, height - 539];
        textHashTypeBorderPositionStart = 353;
        textHashTypeBorderPositionEnd = 506;
      } else {
        textHashTypeRect = [498, height - 525, 344, height - 539];
        textHashTypeBorderPositionStart = 344;
        textHashTypeBorderPositionEnd = 498;
      }
    } else {
      textHashType = "";
      textHashTypeRect = [405, height - 525, 249, height - 539];
      textHashTypeBorderPositionStart = 249;
      textHashTypeBorderPositionEnd = 405;
    }

    const verifyLink = "https://app.vbase.com/verify/";

    // Fix text file
    const textFile = "fіle";
    const textHashTypeAll =
      "Input " +
      (textHashType === "" ? "a" : "the ") +
      textHashType +
      " hash of your " +
      textFile +
      " to app.vbase.com/verify/ under the ContentID tab.";

    secondPage.drawText(textHashTypeAll, {
      x: 63,
      y: height - 536,
      size: 16,
      font: LatoLightFont,
      color: rgb(0, 0, 0),
    });

    secondPage.drawLine({
      start: { x: textHashTypeBorderPositionStart, y: height - 540 },
      end: { x: textHashTypeBorderPositionEnd, y: height - 541 },
      thickness: 1, // Thickness of the underline
      color: rgb(0, 0, 0), // Color of the underline (same as text color)
    });

    const createPageLinkAnnotationVerify2 = (secondPage, uri) =>
      secondPage.doc.context.register(
        secondPage.doc.context.obj({
          Type: "Annot",
          Subtype: "Link",

          Rect: textHashTypeRect, // Area for the link

          A: {
            Type: "Action",
            S: "URI",
            URI: PDFString.of(uri), // URI to open when clicked
          },
        }),
      );

    // Create the link annotation
    const linkVerifyAnnotation2 = createPageLinkAnnotationVerify2(
      secondPage,
      verifyLink,
    );

    // Get the existing annotations (if any)
    const existingAnnotsVerifyAnnotation2 = secondPage.node.get(
      PDFName.of("Annots"),
    );

    // If there are existing annotations, append the new one
    let annotsArrayVerifyAnnotation2 = existingAnnotsVerifyAnnotation2
      ? existingAnnotsVerifyAnnotation2.asArray()
      : [];
    annotsArrayVerifyAnnotation2.push(linkVerifyAnnotation2);

    // Set the updated annotations array back to the page
    secondPage.node.set(
      PDFName.of("Annots"),
      pdfDoc.context.obj(annotsArrayVerifyAnnotation2),
    );

    // Blockchain name
    const blockchainNnameText =
      "equivalent in any other '" + blockchainName + "' blockchain scanner.";
    secondPage.drawText(blockchainNnameText, {
      x: 64,
      y: height - 742,
      size: 16,
      font: LatoLightFont,
      color: rgb(0, 0, 0),
    });

    const textForThisLink =
      "Use ‘Transaction ID’ to find your data’s hash on the relevant blockchain. For example, using ";
    const textWidthThisLinkAll =
      LatoLightFont.widthOfTextAtSize(textForThisLink, 16) + 45;

    const textThisLink = "this link";
    const textWidthThisLink = LatoLightFont.widthOfTextAtSize(textThisLink, 16);

    secondPage.drawLine({
      start: { x: textWidthThisLinkAll, y: height - 727 },
      end: { x: textWidthThisLinkAll + textWidthThisLink, y: height - 728 },
      thickness: 1, // Thickness of the underline
      color: rgb(0, 0, 0), // Color of the underline (same as text color)
    });

    const createPageLinkAnnotationThisLink = (secondPage, uri) =>
      secondPage.doc.context.register(
        secondPage.doc.context.obj({
          Type: "Annot",
          Subtype: "Link",

          Rect: [
            textWidthThisLinkAll,
            height - 713,
            textWidthThisLinkAll + textWidthThisLink,
            height - 727,
          ], // Area for the link

          A: {
            Type: "Action",
            S: "URI",
            URI: PDFString.of(uri), // URI to open when clicked
          },
        }),
      );

    // Create the link annotation
    const thisLinkAnnotation = createPageLinkAnnotationThisLink(
      secondPage,
      linkBlockchain,
    );

    // Get the existing annotations (if any)
    const existingAnnotsThisLinkAnnotation = secondPage.node.get(
      PDFName.of("Annots"),
    );

    // If there are existing annotations, append the new one
    let annotsArrayThisLinkAnnotation = existingAnnotsThisLinkAnnotation
      ? existingAnnotsThisLinkAnnotation.asArray()
      : [];
    annotsArrayThisLinkAnnotation.push(thisLinkAnnotation);

    // Set the updated annotations array back to the page
    secondPage.node.set(
      PDFName.of("Annots"),
      pdfDoc.context.obj(annotsArrayThisLinkAnnotation),
    );

    // Convert data URL directly to an ArrayBuffer
    function dataURLToArrayBuffer(dataURL) {
      const binaryString = atob(dataURL.split(",")[1]); // Get the base64 part of the data URL
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }

    // Generate QR code with the link
    const qrCodeDataUrl = await QRCode.toDataURL(qrLink);
    const qrCodeImageBytes = dataURLToArrayBuffer(qrCodeDataUrl); // Convert data URL to ArrayBuffer
    const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);

    // Current date
    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const contentIDForFileName = formatStampDetails(objectCid, 6, 4, 10);

    // Insert QR code into PDF (bottom right on the first page)
    const qrCodeSize = 100;
    page.drawImage(qrCodeImage, {
      x: width - qrCodeSize - 45,
      y: 190,
      width: qrCodeSize,
      height: qrCodeSize,
    });

    // Save the edited PDF document
    const pdfBytes = await pdfDoc.save();

    // Create a Blob for the PDF download link
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const currentDate = getCurrentDate();
    link.download = `vBase-Stamp-Certificate-${currentDate}-${contentIDForFileName}.pdf`;
    link.click();
  }, [user_id, objectCid, blockExplorerUrl, transactionHash]);
  return (
    <button className="download-pdf" onClick={generatePDF}>
      Download Stamp Certificate PDF
    </button>
  );
};
DownloadPDF.propTypes = {
  data: PropTypes.shape({
    user: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    chainId: PropTypes.string.isRequired,
    objectCid: PropTypes.string.isRequired,
    blockExplorerUrl: PropTypes.string.isRequired,
    transactionHash: PropTypes.string.isRequired,
    blockchainName: PropTypes.string.isRequired,
    collection_name: PropTypes.string.isRequired,
    collection_hash: PropTypes.string.isRequired,
  }).isRequired,
  formattedDateTime: PropTypes.string,
  allHashes: PropTypes.object.isRequired,
};

export default DownloadPDF;
