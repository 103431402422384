import React from "react";

const CurrentPlanSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60.999" height="60.999" viewBox="0 0 60.999 60.999">
      <g id="Group_1122" data-name="Group 1122" transform="translate(0 0.427)">
        <circle id="Ellipse_8" data-name="Ellipse 8" cx="25.535" cy="25.535" r="25.535" transform="translate(4.964 4.537)" />
        <circle id="Ellipse_9" data-name="Ellipse 9" cx="27.5" cy="27.5" r="27.5" transform="translate(3 2.573)" fill="none" stroke="rgba(255,255,255,0.05)" strokeWidth="6"/>
        <path id="Path_7690" data-name="Path 7690" d="M34,46.518l6.234,6.234L52.986,40" transform="translate(-13.431 -16.177)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
      </g>
    </svg>

  );
};

export default CurrentPlanSvg;


