import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LendingPricingSvg from "../svg/LendingPricingSvg";
import CurrentPlanSvg from "../svg/CurrentPlanSvg";
import LoadingPayment from "../components/LoadingPayment";
import { URLs } from "../utils/apis";
import BackToFreePlanWindow from "../components/BackToFreePlanWindow";
import {
  paddleActivePlan,
  handleCheckout,
  handleInitPaddle,
  fetchBillingPlans,
  renderPlanButtonText,
  getPeriodLabel,
} from "../utils/paddle";

const PricingForm = ({ staticBasePath, djangoContext }) => {
  // Billing Cycle can be month or year
  const [billingCycle, setBillingCycle] = useState("month");

  // We are waiting for the payment
  const [loading, setLoading] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleLoading = useCallback((bool) => {
    setLoading(bool);
  }, []);

  // Get email from django
  const { userEmail } = djangoContext;

  // Set active plan
  const [activePlan, setActivePlan] = useState("Free");

  // Memoize the callback function to avoid unnecessary re-renders
  const handleActivePlan = useCallback((plan) => {
    setActivePlan(plan);
  }, []);

  // Current active period
  const [activePeriod, setActivePeriod] = useState("");

  // Memoize the callback function to avoid unnecessary re-renders
  const handleActivePeriod = useCallback((period) => {
    setActivePeriod(period);
  }, []);

  // Set all Plans
  const [allPlans, setAllPlans] = useState([]);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleAllPlans = useCallback((plans) => {
    setAllPlans(plans);
  }, []);

  // Fetch plans and init payment
  useEffect(() => {
    // Get All Plans
    fetchBillingPlans(handleAllPlans);

    handleInitPaddle(handleLoading);
  }, []); // runs once on mount

  // Set active plan
  useEffect(() => {
    if (allPlans && allPlans.length > 0) {
      paddleActivePlan(handleActivePlan, handleActivePeriod, allPlans);
    }
  }, [allPlans]);

  // Show window cancel subscription
  const [backToFreeWindow, setBackToFreeWindow] = useState(false);

  // Handle show window cancel subscription
  const handleClickBackToFreeWindow = () => {
    setBackToFreeWindow(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext} />
      <div className="rb-main-content pricing-page">
        {loading && (
          <div className="rb-screen">
            <LoadingPayment />
          </div>
        )}

        {backToFreeWindow && (
          <div className="rb-screen">
            <BackToFreePlanWindow setBackToFreeWindow={setBackToFreeWindow} />
          </div>
        )}

        <div className="rb-container">
          <div className="pricing-page-header pricing-header">
            <div className="checkout-container"></div>
            <h1 className="rb-header rb-header-stamp rb-text-center">
              Select Your Best Fit
            </h1>
          </div>
          <div className="pricing-page-periods pricing-periods rb-btns-select rb-display-flex rb-justify-content-center">
            <div
              onClick={() => setBillingCycle("month")}
              className={`pricing-page-periods-item pricing-page-periods-month rb-radio-button rb-btn rb-display-flex rb-justify-content-center ${
                billingCycle === "month" ? "pricing-page-periods-active" : ""
              }`}
            >
              <input
                type="radio"
                name="plan"
                value="month"
                id="month"
                defaultChecked
              />
              <label htmlFor="month">Monthly</label>
            </div>
            <div
              onClick={() => setBillingCycle("year")}
              className={`pricing-page-periods-item pricing-periods-item pricing-page-periods-year rb-radio-button rb-btn rb-display-flex rb-justify-content-center ${
                billingCycle === "year" ? "pricing-page-periods-active" : ""
              }`}
            >
              <input type="radio" name="plan" value="year" id="year" />
              <label htmlFor="year">
                Yearly <sup>Save 20%</sup>
              </label>
            </div>
          </div>

          <div className="pricing-page-diy-wrap rb-display-flex rb-justify-content-center">
            <div className="pricing-page-diy rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap rb-justify-content-center">
              <img
                src={`${staticBasePath}public/images/svg/diy.svg`}
                alt="DIY"
              />
              <span>DIY:</span>
              <p>unlimited free stamps and validations forever.</p>
              <a
                target="_blank"
                href="https://docs.vbase.com/"
                rel="noreferrer"
              >
                Always an option.
              </a>
            </div>
          </div>

          <div className="pricing-page-plans pricing-page-block pricing-block rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
            {allPlans
              .sort((a, b) => a.sort_order - b.sort_order)
              .map(
                (product) =>
                  (product?.price?.billing_cycle_interval === billingCycle ||
                    !product?.price ||
                    product?.price?.billing_cycle_interval === "day" ||
                    product?.price?.billing_cycle_interval === "week") && (
                    <div
                      key={product.slug}
                      className={`pricing-page-block-item pricing-block-item pricing-page-plans-item rb-display-flex ${product?.price?.custom_data?.product_type.toLowerCase()}-plan
                  ${
                    product?.is_free
                      ? "pricing-block-item-first pricing-page-plans-item-first"
                      : ""
                  }
    ${
      product?.price?.custom_data?.product_type === "Base"
        ? "pricing-page-plans-item-second pricing-block-item-second"
        : ""
    }
    ${
      product?.price?.custom_data?.product_type === "Pro"
        ? "pricing-page-plans-item-third pricing-block-item-third"
        : ""
    }
                    ${
                      activePlan ===
                        product?.price?.custom_data?.product_type ||
                      (activePlan === "Free" && product?.is_free)
                        ? "pricing-page-block-item-active"
                        : ""
                    }`}
                    >
                      <div className="pricing-page-block-item-head pricing-block-item-head rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
                        <div className="pricing-page-block-item-head-title pricing-block-item-head-title">
                          {product?.is_free
                            ? "Free"
                            : product?.price?.custom_data?.product_type}
                        </div>
                        <div className="pricing-page-block-item-head-subtitle pricing-block-item-head-subtitle">
                          {product?.price?.custom_data?.price_month}
                          {!product?.is_free && <span>/mo</span>}
                        </div>

                        <div className="pricing-page-block-item-head-wrap rb-display-flex rb-flex-wrap-wrap rb-justify-content-center">
                          {(activePlan ===
                            product?.price?.custom_data?.product_type ||
                            (product?.is_free && activePlan === "Free")) && (
                            <div className="pricing-page-block-item-head-current pricing-block-item-head-current">
                              Current Plan
                              {!product?.is_free && (
                                <>/{getPeriodLabel(activePeriod)}</>
                              )}
                            </div>
                          )}

                          <div className="pricing-page-block-item-head-subtitle-year pricing-block-item-head-subtitle-year rb-text-center">
                            &nbsp;
                            {billingCycle === "year" && (
                              <>{product?.price?.custom_data?.price_year}</>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="pricing-page-block-item-body pricing-block-item-body">
                        {product?.is_free ? (
                          <>
                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                100 stamps / month
                              </div>
                            </div>

                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                Unlimited validations*
                              </div>
                            </div>
                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                E-mail and chat support
                              </div>
                            </div>

                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                vBase APIs & Application Hub
                              </div>
                            </div>
                          </>
                        ) : product?.price?.custom_data?.product_type ===
                          "Base" ? (
                          <>
                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                All features of FREE plan
                              </div>
                            </div>

                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                400 stamps / month
                              </div>
                            </div>

                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                Managed service for S3
                              </div>
                            </div>

                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                White glove onboarding
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                All features of BASE plan
                              </div>
                            </div>

                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                2000 stamps/month
                              </div>
                            </div>
                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                Enterprise grade support
                              </div>
                            </div>

                            <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                              <LendingPricingSvg />
                              <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                                vBase Live Sync for automated assurance
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="pricing-page-block-item-footer pricing-block-item-footer rb-display-flex rb-justify-content-center">
                        {(activePlan === "Free" && product?.is_free) ||
                        (activePlan ===
                          product?.price?.custom_data?.product_type &&
                          billingCycle === activePeriod) ? (
                          <CurrentPlanSvg />
                        ) : (
                          <div className="pricing-page-block-buttons pricing-block-buttons pricing-page-plans-item-buttons rb-display-flex rb-justify-content-center rb-align-items-center">
                            <a
                              className={`button rb-display-flex rb-align-items-center

                              ${
                                product?.price?.custom_data?.product_type ===
                                  "Base" || product?.is_free
                                  ? "button-white"
                                  : ""
                              }
                              ${
                                product?.price?.custom_data?.product_type ===
                                "Pro"
                                  ? "button-dark"
                                  : ""
                              }
                              `}
                              href="#"
                              onClick={
                                product?.is_free
                                  ? () => handleClickBackToFreeWindow()
                                  : () =>
                                      handleCheckout(
                                        product?.price?.paddle_id,
                                        userEmail,
                                      )
                              }
                            >
                              {renderPlanButtonText(
                                product?.price?.custom_data?.product_type,
                                activePeriod,
                                activePlan,
                              )}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  ),
              )}

            <div className="pricing-page-plans-custom rb-display-flex">
              <div className="pricing-page-plans-custom-item pricing-page-plans-custom-item-first">
                <div className="pricing-page-plans-custom-item-title">
                  Need Custom Pricing?
                </div>
                <div className="pricing-page-plans-custom-item-buttons rb-display-flex">
                  <a
                    target="_blank"
                    className="button rb-btn button-dark rb-display-flex rb-align-items-center"
                    href={URLs.CONTACTS_URL}
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="pricing-page-plans-custom-item pricing-page-plans-custom-item-second">
                <div className="pricing-page-plans-custom-item-mobile">
                  <span>Please Contact</span>
                  <a href="mailto:support@vbase.com">support@vbase.com</a>
                </div>
                <div className="pricing-page-plans-custom-item-line rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-plans-custom-item-line-text">
                    <b>Customized Solutions:</b> Tailored features,
                    integrations, and support
                  </div>
                </div>
                <div className="pricing-page-plans-custom-item-line rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-plans-custom-item-line-text">
                    <b>Priority Support:</b> 24/7 dedicated support with a
                    personal account manager
                  </div>
                </div>
                <div className="pricing-page-plans-custom-item-line rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-plans-custom-item-line-text">
                    <b>Dedicated Infrastructure:</b> Enhanced performance and
                    scaled usage allowances
                  </div>
                </div>
              </div>
              <div className="pricing-page-plans-custom-item">
                <img
                  className="pricing-page-plans-custom-item-img"
                  src={`${staticBasePath}public/images/png/custom-plan.png`}
                  alt="Custom Plan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer staticBasePath={staticBasePath} />
      {loading && <div className="rb-loading-bg"></div>}
      {backToFreeWindow && <div className="rb-loading-bg"></div>}
    </>
  );
};
PricingForm.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  djangoContext: PropTypes.object.isRequired,
};
export default PricingForm;
