/* global Paddle */
import { URLs } from "./apis";
import React from "react";

// Check payment success
export const waitForCompletedTransactionStatus = async (transaction_id) => {
  // Unfortunately, payment confirmation from PayPal might take around 10 seconds.
  try {
    const maxAttempts = 15; // Maximum number of attempts (30 seconds)
    let attempts = 0; // Initialize the counter
    while (attempts < maxAttempts) {
      try {
        const response = await fetch(
          URLs.PADDLE_TRANSACTION_STATUS_URL + transaction_id,
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch transaction status: ${response.statusText}`,
          );
        }
        const data = await response.json();
        if (data.paddle_status === "completed") {
          await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
          console.log("Transaction completed!");
          return true;
        }
        console.log("Transaction still in progress...");
      } catch (error) {
        console.log("Error checking transaction status:", error);
      }
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
      attempts++; // Increment the counter after each attempt
    }
    if (attempts >= maxAttempts) {
      console.log("Error checking transaction status");
      location.href = URLs.SUBSCRIPTION_FAILED_URL + transaction_id;
      return false;
    }
  } catch (error) {
    console.log("Error checking transaction status:", error);
  }
};

// Get current price paddle id and set current plan and period
export const paddleActivePlan = async (
  handleActivePlan,
  handleActivePeriod,
  allPlans,
) => {
  try {
    const response = await fetch(URLs.PADDLE_DETAILS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    const subscription_price_paddle_id =
      data[0]?.subscription_info?.subscription_price_paddle_id || false;

    // Check and set active plan
    if (allPlans) {
      allPlans.forEach((plan) => {
        if (plan?.price?.paddle_id === subscription_price_paddle_id) {
          handleActivePlan(plan.price.custom_data.product_type);
          handleActivePeriod(plan.price.billing_cycle_interval);
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

// Show window paddle Checkout
export const handleCheckout = (priceID, userEmail) => {
  // Email for paddle
  const customerInfo = {
    email: userEmail,
  };

  const items = [
    {
      quantity: 1,
      priceId: priceID,
    },
  ];

  Paddle.Checkout.open({
    items,
    customer: customerInfo,
    customData: {
      email: userEmail,
    },
  });
};

// Process payment
export const initPaddle = (mode, token, handleLoading) => {
  Paddle.Environment.set(mode);
  Paddle.Initialize({
    token: token,
    checkout: {
      settings: {
        allowLogout: false,
      },
    },
    eventCallback: async function (data) {
      if (data.name === "checkout.completed") {
        handleLoading(true);
        Paddle.Checkout.close();
        handleTransaction(data.data.transaction_id);
      }
    },
  });
};

// Check payment
export const handleTransaction = async (transaction_id) => {
  const transactionStatus = await waitForCompletedTransactionStatus(
    transaction_id,
  );

  if (transactionStatus) {
    location.href = URLs.SUBSCRIPTION_SUCCESS_URL + transaction_id;
  } else {
    console.log("Failed to save the transaction data.");

    location.href = URLs.SUBSCRIPTION_FAILED_URL + transaction_id;
  }
};

// Init payment process
export const handleInitPaddle = async (handleLoading) => {
  try {
    const data = await fetchConfigData();

    const { PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN } = data;
    initPaddle(PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN, handleLoading);
  } catch (error) {
    console.error("Error fetching config data:", error);
  }
};

// Get PADDLE_ENVIRONMENT and PADDLE_CLIENT_SIDE_TOKEN
export const fetchConfigData = async () => {
  try {
    const response = await fetch(URLs.CONFIG_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const { PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN } = data;
    return { PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN };
  } catch (error) {
    console.log(error);
    throw error; // In case the caller wants to handle the error
  }
};

// Get Billing plans
export const fetchBillingPlans = async (handleAllPlans) => {
  try {
    const response = await fetch(URLs.PLANS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    handleAllPlans(data);
  } catch (error) {
    console.log(error);
    throw error; // In case the caller wants to handle the error
  }
};

// Button plan texts
export const renderPlanButtonText = (
  product_type,
  activePeriod,
  activePlan,
) => {
  if (!product_type) {
    product_type = "Free";
  }
  if (product_type === "Free") {
    if (activePlan !== "Free") {
      return <>Cancel Subscription</>;
    }
  }

  if (product_type === "Base") {
    if (activePlan === "Base") {
      return activePeriod === "month" ? (
        <>Switch to Annual Plan</>
      ) : (
        <>Switch to Monthly Plan</>
      );
    } else if (activePlan === "Free") {
      return <>Upgrade to Base</>;
    } else if (activePlan === "Pro") {
      return <>Switch to Base</>;
    }
  }

  if (product_type === "Pro") {
    if (activePlan === "Pro") {
      return activePeriod === "month" ? (
        <>Switch to Annual Plan</>
      ) : (
        <>Switch to Monthly Plan</>
      );
    } else {
      return <>Upgrade to Pro</>;
    }
  }

  return null;
};

// Correct text period
export const getPeriodLabel = (activePeriod) => {
  return activePeriod === "month"
    ? "Monthly"
    : activePeriod === "year"
    ? "Yearly"
    : "";
};
