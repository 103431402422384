import React from "react";
import { handleClickToCopy } from "../../utils/copyToClipboard";
import { formatStampDetails } from "../../utils/formatStampDetails";
import Pagination from "./Pagination";
import { fetchUserData } from "../../utils/apis";
const UserAddressHistory = ({
  staticBasePath,
  userAddressHistoryData,
  setUserAddressHistoryData,
}) => {
  const handleClickRemoveUserAddressHistory = () => {
    setUserAddressHistoryData("");
  };
  // Handle pagination
  const handlePageChange = (newPage) => {
    fetchUserData(newPage, setUserAddressHistoryData); // Call the function with the new page number
  };

  return (
    <>
      <div
        className="user-address-history-bg"
        onClick={() => handleClickRemoveUserAddressHistory()}
      ></div>
      <div className="user-address-history-wrap">
        <img
          className="user-address-history-close"
          src={`${staticBasePath}public/images/svg/rb_close.svg`}
          alt="Close"
          onClick={() => handleClickRemoveUserAddressHistory()}
        />
        <div className="user-address-history-wrap-inner">
          <div className="user-address-history__title">
            User Address History
          </div>
          <div className="html-table-wrap">
            <div className="html-table">
              {userAddressHistoryData.addresses &&
              userAddressHistoryData.addresses.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Is Verified</th>
                      <th>Date From</th>
                      <th>Date To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userAddressHistoryData.addresses.map(
                      (addressHistoryItem, index) => (
                        <tr key={index}>
                          <td>{addressHistoryItem.email}</td>
                          <td>
                            {addressHistoryItem.name
                              ? addressHistoryItem.name
                              : "-"}
                          </td>
                          <td>
                            {addressHistoryItem.address
                              ? formatStampDetails(addressHistoryItem.address)
                              : "-"}
                            <img
                              className="html-table-copy"
                              src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                              alt="copy"
                              onClick={() =>
                                handleClickToCopy(addressHistoryItem.address)
                              }
                            />
                          </td>
                          <td>
                            {addressHistoryItem.is_verified ? "Yes" : "No"}
                          </td>
                          <td>{addressHistoryItem.date_from}</td>
                          <td>
                            {addressHistoryItem.date_to
                              ? addressHistoryItem.date_to
                              : "-"}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              ) : (
                <div>No addresses available</div>
              )}
            </div>
          </div>
          {userAddressHistoryData.total_pages > 1 && (
            <Pagination
              currentPage={userAddressHistoryData.current_page}
              totalPages={userAddressHistoryData.total_pages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UserAddressHistory;
