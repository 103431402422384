import React from "react";
const VerifyContentId = ({
                            isValid,
                            objectHash,
                            handleHashChange,
                             handleSubmit
                        }) => {

    return (
            <div
                id="verify-method-hash__screen"
                className="rb-method-hash__screen"
            >
                <div className="rb-method">
                    <div className="hash-screen-container rb-container">
                        <div className="rb-hash-input rb-hash-input-verify">
                            <p>Content ID:</p>
                            <input
                                type="text"
                                id="object-hash"
                                className={`rb-btn rb-btn-idle rb-input ${isValid ? "" : "invalid"}`}
                                placeholder="Enter content ID"
                                value={objectHash}
                                onChange={handleHashChange}
                            />
                        </div>

                        <div className="rb-hash-input">
                            <button
                                className="rb-btn rb-btn-click rb-btn-verify"
                                onClick={handleSubmit}
                                disabled={!isValid}
                            >
                                Verify
                                <div className="rb-btn-arrow"></div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default VerifyContentId;
