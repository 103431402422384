import React from "react";

const StampContentId = () => {
  return (
    <svg
      className="rb-btn-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="18.937"
      height="21"
      viewBox="0 0 18.937 21"
    >
      <path
        d="M7.621,19.56c-2.3-1.513-4.6-3.034-6.541-4.323A2.3,2.3,0,0,1,0,13.211C0,11.7,0,10.177,0,8.7Q0,7.007,0,5.315A2.394,2.394,0,0,1,.542,3.759a2.364,2.364,0,0,1,1.429-.806A12.8,12.8,0,0,0,7.45.556,2.524,2.524,0,0,1,8.971,0a2.5,2.5,0,0,1,1.491.535,13.11,13.11,0,0,0,5.575,2.407,2.342,2.342,0,0,1,1.9,2.237c0,.918,0,1.851,0,2.753q0,.667,0,1.335c0,1.182,0,2.579,0,3.971a2.246,2.246,0,0,1-1.044,1.975c-2.358,1.571-4.516,3-6.6,4.36A2.4,2.4,0,0,1,8.972,20,2.453,2.453,0,0,1,7.621,19.56Zm.71-17.752A14.737,14.737,0,0,1,2.337,4.419a.866.866,0,0,0-.843,1.017q0,.956,0,1.91t0,1.909c0,1.54,0,2.718-.005,3.818A1.04,1.04,0,0,0,2,14.028c2.2,1.455,4.347,2.88,6.37,4.236a1.212,1.212,0,0,0,.418.189.865.865,0,0,0,.431-.016,1.283,1.283,0,0,0,.357-.172c1.935-1.287,4.024-2.666,6.385-4.218a1,1,0,0,0,.488-.9v-.019c0-.494,0-1.033,0-1.7q0-.526,0-1.052v-.022q0-.553,0-1.107,0-.592,0-1.184v-.01c0-.9,0-1.836,0-2.753a1.016,1.016,0,0,0-.1-.473.818.818,0,0,0-.615-.414A14.757,14.757,0,0,1,9.655,1.809a1.152,1.152,0,0,0-.664-.288A1.144,1.144,0,0,0,8.331,1.808ZM7.015,13Q5.737,11.417,4.457,9.839a.758.758,0,1,1,1.176-.946q.973,1.21,1.951,2.416c.043.053.09.1.148.17l2.2-2.21q1.213-1.216,2.427-2.428a.723.723,0,0,1,.765-.214.741.741,0,0,1,.538.588.791.791,0,0,1-.282.75q-1.187,1.192-2.368,2.388Q9.675,11.707,8.336,13.06a.945.945,0,0,1-.651.335A.891.891,0,0,1,7.015,13Z"
        transform="translate(0.5 0.5)"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
};

export default StampContentId;
