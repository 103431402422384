import React from "react";

const Info = ({ textInfo, staticBasePath }) => {
  return (
    <div className="info-block rb-display-flex rb-align-items-center">
      <div className="info-block__img">
        <img
          src={`${staticBasePath}public/images/svg/rb-info-grey.svg`}
          alt="Info"
        />
      </div>
      <div className="info-block__text">{textInfo}</div>
    </div>
  );
};

export default Info;
