import React from "react";

const VerifiableData = ({staticBasePath}) => {
  return (
    <section className="landing__three verifiable">
      <div className="landing__container">
        <div className="landing__three-title verifiable__title rb-text-center">Verifiable Data Integrity with vBase Stamper</div>
        <div className="landing__three-text verifiable__text rb-text-center">
          <p>vBase offers a blockchain-powered solution for anyone needing a trusted timestamp for their data. A vBase stamp ensures your data remains trustworthy forever.</p>
        </div>

        <div className="landing__three-blocks verifiable__blocks rb-display-flex rb-justify-content-space-between">
          <div
            className="landing__three-blocks-item verifiable__blocks-item">
            <div className="verifiable__blocks-item-content landing__three-blocks-item-content">
              <img
                src={`${staticBasePath}public/images/svg/landing_authenticated _proof.svg`}
                alt="Authenticated Proof"
              />
              <span>Authenticated Proof</span>
              <p>vBase offers a reliable approach for verifying the authenticity of data, enhancing trust.</p>
            </div>

          </div>
          <div
            className="landing__three-blocks-item verifiable__blocks-item">
            <div className="verifiable__blocks-item-content landing__three-blocks-item-content">
              <img
                src={`${staticBasePath}public/images/svg/landing_proof_of_existence.svg`}
                alt="Proof of Existence"
              />
              <span>Proof of Existence</span>
              <p>Establish what data you had when, and who stamped it. Create an unchangeable verifiable digital history.</p>
            </div>

          </div>
          <div
            className="landing__three-blocks-item verifiable__blocks-item">
            <div className="verifiable__blocks-item-content landing__three-blocks-item-content">
              <img
                src={`${staticBasePath}public/images/svg/landing_simple_long_lived.svg`}
                alt="Proof of Existence"
              />
              <span>Simple and Long-lived</span>
              <p>No receipts to keep. No 3rd party dependencies. Use blockchain to create a permanent record that is verifiable even if vBase disappears.</p>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
    ;
};

export default VerifiableData;
