import React, { useState, useEffect } from "react";
import { fetchStatisticsData } from "../../utils/apis";

const UsageStatistics = () => {
  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    last_login: "",
    date_of_account_created: "",
  });

  // Fetch user data from API
  useEffect(() => {
    fetchStatisticsData(setInputValues);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="user-profile-tabs-content-item billing">
      {inputValues["last_login"] && (
        <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
          <label htmlFor="date-of-last-login">Date of Last Login:</label>
          <input
            id="date_of_last_login"
            className="rb-input"
            defaultValue={inputValues["last_login"]}
            disabled="disabled"
          />
          <button></button>
        </div>
      )}
      {inputValues["user_joined"] && (
        <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
          <label htmlFor="date-of-last-login">Date of Account Created:</label>
          <input
            id="date_of_last_login"
            className="rb-input"
            defaultValue={inputValues["user_joined"]}
            disabled="disabled"
          />
          <button></button>
        </div>
      )}
    </div>
  );
};

export default UsageStatistics;
