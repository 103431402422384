import React from "react";

const FooterFormArrowSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20"><text transform="translate(0 16)" fontSize="20" fontFamily="ZapfDingbatsITC, Zapf Dingbats"><tspan x="0" y="0">➔</tspan></text></svg>
  );
};

export default FooterFormArrowSvg;


