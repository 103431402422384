import React from "react";
import {handleButtonClickDeleteUser} from "../../utils/userProfile";

const UserDelete = ({staticBasePath, setUserDelete}) => {
  const handleButtonClickPopupHide = () => {
    setUserDelete(false);
  };
  return (
    <>
      <div className="user-delete-bg"
           onClick={() =>
             handleButtonClickPopupHide()
           }
      ></div>
      <div className="user-delete-wrap">
        <img
          src={`${staticBasePath}public/images/svg/rb_close.svg`}
          alt="Close"
          onClick={() =>
            handleButtonClickPopupHide()
          }
        />

        <div className="user-delete__title">
          Delete my Profile?
        </div>
        <div className="user-delete__content rb-display-flex rb-justify-content-center">
          <button className="user-delete__content-button user-delete__content-yes"
                  onClick={() =>
                    handleButtonClickDeleteUser()
                  }
          >Yes</button>
          <button className="user-delete__content-button"
                  onClick={() =>
                    handleButtonClickPopupHide()
                  }
          >No
          </button>
        </div>

      </div>
    </>
  );
};

export default UserDelete;
