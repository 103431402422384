import React from "react";

const LogoBlockchainSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.502" height="20.491" viewBox="0 0 20.502 20.491">
      <path
        d="M157.208,0l3.632,1.927c.1.055.207.112.328.178-.072.039-.123.07-.175.1q-1.895.948-3.792,1.89a.351.351,0,0,1-.27.018c-1.3-.643-2.6-1.295-3.9-1.945-.029-.015-.056-.034-.1-.061.075-.041.137-.077.2-.11L156.88.018s.006-.012.008-.018Z"
        transform="translate(-146.798 0)"/>
      <path
        d="M0,356.4a.931.931,0,0,1,.148.045q1.676.827,3.352,1.654a.215.215,0,0,1,.147.223c-.005,1.388,0,2.776,0,4.164,0,.026,0,.053-.007.108-.075-.034-.139-.06-.2-.091-.974-.481-1.946-.966-2.923-1.44a.92.92,0,0,1-.513-.5V356.4"
        transform="translate(0 -342.108)"/>
      <path
        d="M11.728,91.777l-1.331-.7L8.715,94.143l1.739.905c-.072.041-.124.073-.178.1q-1.895.948-3.792,1.89a.352.352,0,0,1-.27.016c-1.3-.638-2.6-1.284-3.906-1.93-.04-.02-.077-.046-.131-.079l1.667-.857c.693-.356,1.391-.7,2.078-1.071a.783.783,0,0,1,.83.006c.282.164.578.3.893.466L9.32,90.54c-.212-.108-.41-.229-.622-.312a.842.842,0,0,1-.569-.918c.023-1.181.008-2.362.008-3.543v-.243c.073.03.127.049.177.074l3.272,1.634c.092.046.159.084.159.216-.007,1.408,0,2.816,0,4.223,0,.026-.005.051-.011.106"
        transform="translate(-2.09 -82.094)"/>
      <path
        d="M274.567,93.682c.337-.175.662-.34.985-.511a.66.66,0,0,1,.65,0q1.875.964,3.751,1.926a.929.929,0,0,1,.08.055c-.067.036-.124.068-.182.1q-1.907.945-3.817,1.885a.353.353,0,0,1-.27.019c-1.3-.642-2.6-1.294-3.9-1.945-.029-.015-.056-.033-.106-.063l1.739-.906-1.681-3.067-1.329.7c-.006-.074-.014-.129-.014-.185q0-2.052,0-4.1c0-.1,0-.181.12-.239,1.129-.557,2.254-1.123,3.38-1.685.022-.011.047-.018.092-.034,0,.076.012.138.012.2,0,1.241,0,2.482,0,3.723a.7.7,0,0,1-.441.713c-.246.113-.486.242-.747.372l1.674,3.053"
        transform="translate(-259.624 -82.184)"/>
      <path
        d="M124.366,356.038v1.4h3.6v-1.4c.077.032.131.051.182.076q1.636.816,3.272,1.633c.093.046.157.087.157.218-.007,1.388,0,2.775,0,4.163,0,.032,0,.064-.008.117-.071-.032-.131-.056-.189-.084-.983-.491-1.964-.987-2.951-1.471a.729.729,0,0,1-.458-.744c.014-.419,0-.84,0-1.275h-3.6a9.5,9.5,0,0,0,.007,1.027.982.982,0,0,1-.685,1.111c-.922.417-1.816.9-2.722,1.348-.058.029-.118.054-.194.088,0-.069-.009-.12-.009-.171q0-2.041,0-4.083c0-.094,0-.17.11-.225,1.129-.557,2.254-1.123,3.38-1.685.028-.014.058-.022.111-.042"
        transform="translate(-115.921 -341.752)"/>
      <path
        d="M420.138,362.287v-.214c0-1.334,0-2.668,0-4a.269.269,0,0,1,.171-.29c1.1-.533,2.187-1.076,3.279-1.616.053-.026.107-.049.18-.082.007.064.016.113.016.161,0,1.267,0,2.535,0,3.8a.657.657,0,0,1-.383.646l-3.135,1.547c-.034.017-.071.027-.127.047"
        transform="translate(-403.282 -341.8)"/>
    </svg>
  );
};

export default LogoBlockchainSvg;


