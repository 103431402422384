import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

const FrontendPaginationCollections = ({
  items,
  itemsPerPage,
  onFilteredItemsCollectionsChange,
  currentPage,
  onCurrentPage,
}) => {
  // Calculate the total number of pages
  const totalPages = Math.ceil(items.length / itemsPerPage);

  // Get the items for the current page
  const currentItems = useMemo(
    () =>
      items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [items, currentPage, itemsPerPage],
  );

  useEffect(() => {
    onFilteredItemsCollectionsChange(currentItems);
  }, [currentItems, onFilteredItemsCollectionsChange]);

  // Generate the page numbers for navigation
  const delta = 1;
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const left = Math.max(2, currentPage - delta);
    const right = Math.min(totalPages - 1, currentPage + delta);

    // Always include the first page
    pageNumbers.push(1);

    // Add an ellipsis if there's a gap between the first and left boundary
    if (left > 2) {
      pageNumbers.push("...");
    }

    // Add pages around the current page
    for (let i = left; i <= right; i++) {
      pageNumbers.push(i);
    }

    // Add an ellipsis if there's a gap between the right boundary and last page
    if (right < totalPages - 1) {
      pageNumbers.push("...");
    }

    // Always include the last page
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div>
      {/* Pagination controls */}
      {items.length > itemsPerPage && (
        <div className="pagination-controls rb-display-flex rb-justify-content-center">
          {/* Button to navigate to the first page */}
          <button
            className={`pagination-controls-item pagination-controls-first ${
              currentPage === 1 ? "pagination-controls-disabled" : ""
            }`}
            onClick={() => onCurrentPage(1)}
            disabled={currentPage === 1}
          >
            First
          </button>

          {/* Button to navigate to the previous page */}
          <button
            className={`pagination-controls-item pagination-controls-previous ${
              currentPage === 1 ? "pagination-controls-disabled" : ""
            }`}
            onClick={() => onCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {/* Generate and render page numbers */}
          {generatePageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() => typeof page === "number" && onCurrentPage(page)}
              disabled={page === currentPage || page === "..."}
              className={`pagination-controls-item ${
                page === currentPage ? "pagination-controls-active" : ""
              }`}
            >
              {page}
            </button>
          ))}

          {/* Button to navigate to the next page */}
          <button
            className={`pagination-controls-item pagination-controls-next ${
              currentPage === totalPages ? "pagination-controls-disabled" : ""
            }`}
            onClick={() => onCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>

          {/* Button to navigate to the last page */}
          <button
            className={`pagination-controls-item pagination-controls-last ${
              currentPage === totalPages ? "pagination-controls-disabled" : ""
            }`}
            onClick={() => onCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          >
            Last
          </button>
        </div>
      )}
    </div>
  );
};

FrontendPaginationCollections.propTypes = {
  items: PropTypes.array.isRequired, // Array type for items
  itemsPerPage: PropTypes.number.isRequired, // Number type for itemsPerPage
  onFilteredItemsCollectionsChange: PropTypes.func.isRequired, // Function type for onFilteredItemsChange
  onCurrentPage: PropTypes.func.isRequired, // Function type for onCurrentPage
  currentPage: PropTypes.number.isRequired, // Number type for currentPage
};

export default FrontendPaginationCollections;
