import React from "react";
import { URLs } from "../utils/apis";

const Notification = () => {
  return (
    <div className="rb-notification">
      <div className="rb-notification-phantom" />
      <div className="rb-notification-block">
        <div className="rb-notification-text">
          <p>
            To start making stamps, <b>get your FREE vBase account!</b>{" "}
          </p>

          <div className="rb-notification-buttons rb-display-flex rb-justify-content-space-between">
            <a
              href={URLs.ACCOUNTS_SIGNUP_URL}
              className="rb-btn rb-btn-white rb-notification-buttons-item"
            >
              Register
            </a>
            <a
              href={URLs.ACCOUNTS_LOGIN_URL}
              className="rb-btn rb-btn-white rb-notification-buttons-item"
            >
              Sign In
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
