import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { fetchQuotaData } from "../../utils/apis";
import moment from "moment";
const QuotaLimitHeader = ({ setIsOpen, currentScreen, updateStampQuota }) => {
  const formattedDateTime = (date) =>
    moment(date, "YYYY-MM-DD hh:mm:ss").format("MM/DD/YYYY");

  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [userBalanceInfo, setUserBalanceInfo] = useState("");
  const [subscriptionInfo, setSubscriptionInfo] = useState("");
  const { title: planTitle = "" } = subscriptionPlan;
  const { next_billed_at = "" } = subscriptionInfo;
  const {
    expirable_credits = 0,
    paid_credits = 0,
    total_credits = 0,
  } = userBalanceInfo;

  const [isActiveHover, setIsActiveHover] = useState(false);
  const handleMouseEnter = () => {
    setIsActiveHover(true);
    setIsOpen(false);
  };

  const handleMouseLeave = () => {
    setIsActiveHover(false);
  };

  useEffect(() => {
    const fetchQuota = async () => {
      try {
        const { subscriptionPlan, userBalanceInfo, subscriptionInfo } =
          await fetchQuotaData(); // Use the utility function to get data
        setSubscriptionPlan(subscriptionPlan);
        setUserBalanceInfo(userBalanceInfo);
        setSubscriptionInfo(subscriptionInfo);
      } catch (error) {
        // console.log(error); // Handle any errors
      }
    };
    if (currentScreen === "created") {
      fetchQuota(); // Call the function to fetch quota data
    }
    fetchQuota(); // Call the function to fetch quota data
  }, [currentScreen, updateStampQuota]);

  return (
    <div className="quota-limit-header">
      <button
        className={isActiveHover ? "active" : ""}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {total_credits} stamps available
      </button>
      <div
        className={`quota-limit-header-menu-wrap ${
          isActiveHover ? "active" : ""
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ul className="quota-limit-header-menu">
          <li className="quota-limit-header-menu-item quota-limit-header-menu-item-title">
            Current Stamp Balance:
          </li>
          <li className="quota-limit-header-menu-item">
            Monthly Free Stamp Credits: <b>{expirable_credits}</b>
          </li>
          <li className="quota-limit-header-menu-item">
            Purchased Stamp Credits: <b>{paid_credits}</b>
          </li>
          <li className="quota-limit-header-menu-item">
            Total Stamp Credits: <b>{total_credits}</b>
          </li>
          <li className="quota-limit-header-menu-item quota-limit-header-menu-item-divider"></li>

          <li className="quota-limit-header-menu-item">
            Plan: <b>{planTitle}</b>
          </li>
          {next_billed_at && (
            <li className="quota-limit-header-menu-item">
              Next billing date: <b>{formattedDateTime(next_billed_at)}</b>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

QuotaLimitHeader.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  currentScreen: PropTypes.string,
  updateStampQuota: PropTypes.bool,
};

export default QuotaLimitHeader;
