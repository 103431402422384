export const pingForwarder = async (isAuthenticated) => {
  let isApiAvailable = false;

  if (!isAuthenticated) {
    return;
  }

  console.log("pingForwarder: Calling API");
  try {
    const response = await fetch("/stamp/ping-forwarder/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("pingForwarder: Network response was not ok");
    }
    const result = await response.json();
    isApiAvailable = result.success;
  } catch (error) {
    console.error("Error:", error);
  }
  console.log("Result :pingForwarder:", isApiAvailable);
};
