import PropTypes from "prop-types";
import React from "react";
import UserInfo from "./UserInfo";
import Billing from "./Billing";
import AccountSettings from "./AccountSettings";
import UsageStatistics from "./UsageStatistics";
import CollectionNames from "./CollectionNames";

const TabContentUserProfile = ({
  activeTabUserProfile,
  setUserAddressHistoryData,
  setUserDelete,
  setChangePassword,
  setChangedUserName,
  changedUserName,
  staticBasePath,
  onUpdateStampQuota,
  onShowConfirmationCollectionCreate,
  showConfirmationCollectionCreate,
  onNameCollection,
  nameCollection,
  nameCollectionSuccess,
  onNameCollectionError,
  nameCollectionError,
}) => {
  switch (activeTabUserProfile) {
    case "#user-info":
      return (
        <UserInfo
          setUserAddressHistoryData={setUserAddressHistoryData}
          setUserDelete={setUserDelete}
          setChangePassword={setChangePassword}
          setChangedUserName={setChangedUserName}
          changedUserName={changedUserName}
        />
      );
    case "#billing":
      return <Billing />;
    case "#account-settings":
      return <AccountSettings />;
    case "#usage-statistics":
      return <UsageStatistics />;
    case "#collections":
      return (
        <CollectionNames
          staticBasePath={staticBasePath}
          onUpdateStampQuota={onUpdateStampQuota}
          onShowConfirmationCollectionCreate={
            onShowConfirmationCollectionCreate
          }
          showConfirmationCollectionCreate={showConfirmationCollectionCreate}
          onNameCollection={onNameCollection}
          nameCollection={nameCollection}
          nameCollectionSuccess={nameCollectionSuccess}
          onNameCollectionError={onNameCollectionError}
          nameCollectionError={nameCollectionError}
        />
      );
    default:
      return (
        <UserInfo
          setUserAddressHistoryData={setUserAddressHistoryData}
          setUserDelete={setUserDelete}
          setChangePassword={setChangePassword}
        />
      );
  }
};
TabContentUserProfile.propTypes = {
  activeTabUserProfile: PropTypes.string.isRequired,
  setUserAddressHistoryData: PropTypes.func.isRequired,
  setUserDelete: PropTypes.func.isRequired,
  setChangePassword: PropTypes.func.isRequired,
  setChangedUserName: PropTypes.func.isRequired,
  changedUserName: PropTypes.bool,
  staticBasePath: PropTypes.string.isRequired,
  onUpdateStampQuota: PropTypes.func.isRequired,
  onNameCollection: PropTypes.func.isRequired,
  nameCollection: PropTypes.string.isRequired,
  nameCollectionSuccess: PropTypes.string.isRequired,
  onNameCollectionError: PropTypes.func.isRequired,
  nameCollectionError: PropTypes.bool.isRequired,
  onShowConfirmationCollectionCreate: PropTypes.func,
  showConfirmationCollectionCreate: PropTypes.bool.isRequired,
};
export default TabContentUserProfile;
