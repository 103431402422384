import React from "react";
import Help from "../Help";
import PropTypes from "prop-types";
const ReportBlock = ({
  Background,
  Color,
  Text,
  Number,
  position,
  text,
  link,
}) => {
  return (
    <div
      className="reports-block"
      style={{ backgroundColor: Background, color: Color }}
    >
      <b>{Text}</b>
      <span className="reports-block-number">{Number}</span>
      {(text || link) && <Help position={position} text={text} link={link} />}
    </div>
  );
};

ReportBlock.propTypes = {
  Background: PropTypes.string,
  Color: PropTypes.string,
  Text: PropTypes.string,
  Number: PropTypes.string,
  position: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
};
export default ReportBlock;
