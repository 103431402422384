import React from "react";
import PropTypes from "prop-types";
import LendingPricingSvg from "../../svg/LendingPricingSvg";
import CurrentPlanSvg from "../../svg/CurrentPlanSvg";

const Pricing = ({ isAuthenticated, staticBasePath }) => {
  return (
    <section className="pricing">
      <div className="landing__container">
        <div className="pricing__title">Select Your Best Fit</div>
        <div className="pricing__text">Plans built for every team.</div>

        <div className="pricing-page-diy-wrap rb-display-flex rb-justify-content-center">
          <div className="pricing-page-diy rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap rb-justify-content-center">
            <img src={`${staticBasePath}public/images/svg/diy.svg`} alt="DIY" />
            <span>DIY:</span>
            <p>unlimited free stamps and validations forever.</p>
            <a target="_blank" href="https://docs.vbase.com/" rel="noreferrer">
              Always an option.
            </a>
          </div>
        </div>

        <div className="pricing-block rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
          <div className="pricing-block-item pricing-block-item-second rb-display-flex">
            <div className="pricing-block-item-head">
              <div className="pricing-block-item-head-title">Free</div>
              <div className="pricing-block-item-head-subtitle"></div>
            </div>
            <div className="pricing-block-item-body">
              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  100 stamps / month
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  Unlimited validations*
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  E-mail and chat support
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  vBase APIs & Application Hub
                </div>
              </div>
            </div>
            <div className="pricing-page-block-item-footer pricing-block-item-footer rb-display-flex rb-justify-content-center">
              {isAuthenticated ? (
                <CurrentPlanSvg />
              ) : (
                <div className="pricing-block-buttons rb-display-flex rb-justify-content-center rb-align-items-center">
                  <a
                    className="button button-white rb-display-flex rb-align-items-center"
                    target="_blank"
                    rel="noreferrer"
                    href="/accounts/signup/"
                  >
                    Get Started
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className="pricing-block-item pricing-block-item-first rb-display-flex">
            <div className="pricing-block-item-head">
              <div className="pricing-block-item-head-title">BASE</div>
              <div className="pricing-block-item-head-subtitle">$50/mo</div>
            </div>
            <div className="pricing-block-item-body">
              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  All features of FREE plan
                </div>
              </div>
              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  400 stamps / month
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  Managed service for S3
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  White glove onboarding
                </div>
              </div>
            </div>
            <div className="pricing-page-block-item-footer pricing-block-item-footer rb-display-flex rb-justify-content-center">
              <div className="pricing-block-buttons rb-display-flex rb-justify-content-center rb-align-items-center">
                <a
                  className="button button-white rb-display-flex rb-align-items-center"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.vbase.com/contact/"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>

          <div className="pricing-block-item pricing-block-item-third rb-display-flex">
            <div className="pricing-block-item-head">
              <div className="pricing-block-item-head-title">PRO</div>
              <div className="pricing-block-item-head-subtitle">$200/mo</div>
            </div>
            <div className="pricing-block-item-body">
              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  All features of BASE plan
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  2000 stamps/month
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  Enterprise grade support
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg />
                <div className="pricing-block-item-body-item-text">
                  vBase Live Sync for automated assurance
                </div>
              </div>
            </div>
            <div className="pricing-block-item-footer">
              <div className="pricing-block-buttons rb-display-flex rb-justify-content-center rb-align-items-center">
                {isAuthenticated ? (
                  <a
                    className="button button-dark rb-display-flex rb-align-items-center"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.vbase.com/contact/"
                  >
                    Get Started
                  </a>
                ) : (
                  <a
                    className="button button-dark rb-display-flex rb-align-items-center"
                    target="_blank"
                    href="/accounts/signup/"
                  >
                    Get Started
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
Pricing.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
export default Pricing;
