import React from "react";

const SocialXSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31.985" viewBox="0 0 32 31.985">
      <g id="Group_535" data-name="Group 535" transform="translate(-1777 -5223.828)">
        <path id="Path_6691" data-name="Path 6691" d="M0,17.949V15.7c.02-.08.046-.159.058-.24.106-.7.172-1.4.321-2.091a16,16,0,0,1,31.4,6.078,15.789,15.789,0,0,1-4.962,9.144A15.6,15.6,0,0,1,18.8,32.565c-.56.1-1.126.166-1.689.248H14.867c-.358-.046-.718-.088-1.075-.14a15.788,15.788,0,0,1-8.224-3.729,15.723,15.723,0,0,1-5.318-9.3c-.1-.56-.167-1.127-.249-1.69M25.18,26.2,17.742,15.378l6.846-7.959c-.481,0-.875.015-1.267-.006a.657.657,0,0,0-.587.264q-2.2,2.576-4.411,5.139c-.429.5-.861,1-1.3,1.5-.046-.062-.072-.094-.1-.128q-2.244-3.262-4.485-6.527a.485.485,0,0,0-.452-.251c-1.624.01-3.247,0-4.871.006-.087,0-.175.013-.308.024l7.162,10.424L6.806,26.2c.5,0,.924-.01,1.348,0A.514.514,0,0,0,8.605,26q2.426-2.833,4.863-5.656c.4-.47.81-.938,1.226-1.42.084.116.146.2.2.285q2.295,3.341,4.582,6.687a.632.632,0,0,0,.606.312c1.249-.014,2.5-.006,3.747-.006H25.18" transform="translate(1777 5223)" />
        <path id="Path_6695" data-name="Path 6695" d="M144.5,125.722c.8,0,1.565-.009,2.332.011.1,0,.219.135.289.235q3.614,5.156,7.22,10.319l3.859,5.518c.059.084.115.171.195.291a2.453,2.453,0,0,1-.254.029c-.656,0-1.311.01-1.967-.007a.5.5,0,0,1-.355-.173q-5.648-8.052-11.28-16.116a.7.7,0,0,1-.04-.107" transform="translate(1641.542 5105.922)" />
      </g>
    </svg>
  );
};

export default SocialXSvg;


