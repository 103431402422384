export const formatCollection = (name, id) => {
  if (window.innerWidth < 768) {
    if (name.length > 28) {
      return `${name.slice(0, 7)} ... ${name.slice(-6)} (ID: ${id.slice(
        2,
        6,
      )})`;
    }
  } else {
    if (name.length > 80) {
      return `${name.slice(0, 40)} ... ${name.slice(-20)} (ID: ${id.slice(
        2,
        6,
      )})`;
    }
  }
  return name;
};
