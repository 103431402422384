import React from "react";

const SocialLinkedinSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31.983" viewBox="0 0 32 31.983">
      <path id="Path_6690" data-name="Path 6690" d="M17.115,31.982H14.866c-.358-.046-.718-.088-1.075-.14a15.778,15.778,0,0,1-8.319-3.81A15.717,15.717,0,0,1,.249,18.81c-.1-.56-.167-1.127-.249-1.69V14.872c.026-.194.055-.388.077-.583A15.533,15.533,0,0,1,2.634,7.211,15.705,15.705,0,0,1,15.136.033a15.517,15.517,0,0,1,8.872,2.122,16.041,16.041,0,0,1,7.767,16.463,15.805,15.805,0,0,1-4.962,9.142A15.611,15.611,0,0,1,18.8,31.735c-.56.1-1.126.166-1.689.248m.265-7.82V23.77c0-2.029,0-4.058,0-6.087A2.43,2.43,0,0,1,17.922,16a2.034,2.034,0,0,1,2.107-.688,1.724,1.724,0,0,1,1.326,1.419,4,4,0,0,1,.126.923c.012,2.05.009,4.1.01,6.15,0,.12,0,.239,0,.355h3.882V23.78c0-1.852.006-3.7,0-5.557a16.4,16.4,0,0,0-.078-1.961,4.2,4.2,0,0,0-3.964-4.05A3.882,3.882,0,0,0,17.6,13.834c-.05.07-.109.133-.221.269V12.5H13.516V24.163Zm-9.9,0h3.833V12.491H7.475ZM7.234,8.833a2.007,2.007,0,0,0,2.134,2.029,2.03,2.03,0,1,0,.049-4.046A2.023,2.023,0,0,0,7.234,8.833" transform="translate(0 0.001)"/>
    </svg>
  );
};

export default SocialLinkedinSvg;


