import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

const Loading = ({ text = "" }) => {
  return (
    <div className="rb-loading-wrap">
      <div className="rb-loading__loader">
        <motion.span
          animate={{
            rotate: 360,
          }}
          transition={{
            repeat: Infinity,
            duration: 1,
            ease: "linear",
          }}
        />
      </div>
      <div className="rb-loading__title">{text}</div>
      <div className="rb-loading__text">
        Can take 20 seconds, but usually faster!
      </div>
    </div>
  );
};

Loading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Loading;
