import React from "react";
import LendingPricingSvg from "../../svg/LendingPricingSvg";

const SupplementaryInformation = ({staticBasePath}) => {
  return (
    <section className="landing__three supplementary-information">
      <div className="landing__container">
        <div className="landing__three-title supplementary-information__title">Supplementary Information</div>

        <div
          className="landing__three-blocks supplementary-information__blocks rb-display-flex rb-justify-content-space-between">
          <div
            className="landing__three-blocks-item supplementary-information__blocks-item">
            <div
              className="supplementary-information__blocks-item-content landing__three-blocks-item-content rb-display-flex rb-flex-direction-column">
              <div className="supplementary-information__blocks-item-content-img landing__three-blocks-item-content-img">
                <img
                  src={`${staticBasePath}public/images/svg/landing_blog_insights.svg`}
                  alt="Blog Insights"
                />
              </div>
              <span>Blog Insights</span>
              <p>Explore our blog for insights, expert tips, and real-world examples on ensuring data integrity and transparency.</p>

              <div
                className="landing-buttons supplementary-information__buttons rb-display-flex rb-align-items-center">
                <a className="button rb-btn button-white rb-display-flex rb-align-items-center"
                   target="_blank"
                   href="https://www.vbase.com/blog/">
                  Visit Blog
                  <LendingPricingSvg/>
                </a>
              </div>
            </div>

          </div>
          <div
            className="landing__three-blocks-item supplementary-information__blocks-item">
            <div
              className="supplementary-information__blocks-item-content landing__three-blocks-item-content rb-display-flex rb-flex-direction-column">
              <div className="supplementary-information__blocks-item-content-img landing__three-blocks-item-content-img">
                <img
                  src={`${staticBasePath}public/images/svg/landing_detailed_guide.svg`}
                  alt="Detailed Guide"
                />
              </div>
              <span>Detailed Guide</span>
              <p>Master the vBase Data Stamper with our step-by-step guide. Assure your data’s provenance with ease and confidence.</p>
              <div
                className="landing-buttons supplementary-information__buttons rb-display-flex  rb-align-items-center">
                <a className="button rb-btn button-white rb-display-flex rb-align-items-center"
                   target="_blank"
                   href="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper">
                  How-to Guide
                  <LendingPricingSvg/>
                </a>
              </div>
            </div>

          </div>
          <div
            className="landing__three-blocks-item supplementary-information__blocks-item">
            <div
              className="supplementary-information__blocks-item-content landing__three-blocks-item-content rb-display-flex rb-flex-direction-column">
              <div className="supplementary-information__blocks-item-content-img landing__three-blocks-item-content-img">
                <img
                  src={`${staticBasePath}public/images/svg/landing_developer_resources.svg`}
                  alt="Developer Resources"
                />
              </div>
              <span>Developer Resources</span>
              <p>Learn how vBase works under the hood. Access our comprehensive documentation for a full description, how-tos, samples and more.</p>
              <div
                className="landing-buttons supplementary-information__buttons rb-display-flex  rb-align-items-center">
                <a className="button rb-btn button-white rb-display-flex rb-align-items-center"
                   target="_blank"
                   href="https://docs.vbase.com/">
                  Read the Docs
                  <LendingPricingSvg/>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
    ;
};

export default SupplementaryInformation;
