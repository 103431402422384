import React, { useState, useEffect } from "react";
import { handleClickToCopy } from "../../utils/copyToClipboard";
import { URLs } from "../../utils/apis";
const AccountSettings = () => {
  const [fieldTypeApiKey, setFieldTypeApiKey] = useState("password");

  const handleClickApiKeyToggleVisibility = () => {
    setFieldTypeApiKey((prevTypeApiKey) =>
      prevTypeApiKey === "password" ? "text" : "password",
    );
  };

  const [fieldTypePrivateKey, setFieldTypePrivateKey] = useState("password");

  const handleClickPrivateKeyToggleVisibility = () => {
    setFieldTypePrivateKey((prevTypePrivateKey) =>
      prevTypePrivateKey === "password" ? "text" : "password",
    );
  };

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    api_key: "",
    private_key: "",
  });

  // Fetch user data from API
  useEffect(() => {
    const fetchQuotasData = async () => {
      try {
        const response = await fetch(URLs.PROFILE_API_KEYS_URL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setInputValues({
          api_key: data.api_key || "",
          private_key: data.private_key || "",
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchQuotasData();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="user-profile-tabs-content-item billing">
      <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
        <label htmlFor="api-key">API Key:</label>
        <div className="rb-input-wrap">
          <input
            id="api-key"
            className="rb-input"
            defaultValue={inputValues["api_key"]}
            type={fieldTypeApiKey}
            readOnly="readonly"
          />
          <button
            className="btn btn-show-hide"
            onClick={() => handleClickApiKeyToggleVisibility()}
          >
            <i
              className={`bi ${
                fieldTypeApiKey === "password" ? "bi-eye" : "bi-eye-slash"
              }`}
              id="toggle-icon"
            ></i>
          </button>
        </div>
        <button onClick={() => handleClickToCopy(inputValues["api_key"])}>
          Copy
        </button>
      </div>
      <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
        <label htmlFor="private-key">Private Key:</label>
        <div className="rb-input-wrap">
          <input
            id="private-key"
            className="rb-input"
            defaultValue={inputValues["private_key"]}
            type={fieldTypePrivateKey}
            readOnly="readonly"
          />
          <button
            className="btn btn-show-hide"
            onClick={() => handleClickPrivateKeyToggleVisibility()}
          >
            <i
              className={`bi ${
                fieldTypePrivateKey === "password" ? "bi-eye" : "bi-eye-slash"
              }`}
              id="toggle-icon"
            ></i>
          </button>
        </div>
        <button onClick={() => handleClickToCopy(inputValues["private_key"])}>
          Copy
        </button>
      </div>
    </div>
  );
};

export default AccountSettings;
