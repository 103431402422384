import React from "react";
import { motion } from "framer-motion";

const LoadingUserFound = () => {
  return (
    <div className="rb-loading-wrap">
      <div className="rb-loading__loader">
        <motion.span
          animate={{
            rotate: 360,
          }}
          transition={{
            repeat: Infinity,
            duration: 1,
            ease: "linear",
          }}
        />
      </div>
      <div className="rb-loading__title">Please wait, retrieving data</div>
      <div className="rb-loading__text">
        Can take 10 seconds, but usually faster!
      </div>
    </div>
  );
};
export default LoadingUserFound;
