import PropTypes from "prop-types";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SubscriptionNotFound from "../components/SubscriptionNotFound";

const SubscriptionMotFoundPage = ({ staticBasePath, djangoContext }) => {
  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext} />
      <div className="rb-main-content pricing-page">
        <div className="rb-container">
          <SubscriptionNotFound staticBasePath={staticBasePath} />
        </div>
      </div>
      <Footer staticBasePath={staticBasePath} />
    </>
  );
};
SubscriptionMotFoundPage.propTypes = {
  staticBasePath: PropTypes.object.isRequired,
  djangoContext: PropTypes.object.isRequired,
};
export default SubscriptionMotFoundPage;
