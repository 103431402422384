import PropTypes from "prop-types";
import React from "react";
import { fetchIsPinnedCollection } from "../../utils/apis";

const IsPinnedCollectionWindow = ({
  setShowIsPinnedWindow,
  collectionID,
  setNameCollectionIsPinned,
  isPinned,
}) => {
  // Hide window Pinned Collection
  const handleClickIsPinnedCollectionClose = () => {
    setShowIsPinnedWindow(false);
    document.body.style.overflow = "";
  };

  // Pinned Collection name
  const handleClickIsPinnedCollection = async () => {
    fetchIsPinnedCollection(
      collectionID,
      isPinned,
      setShowIsPinnedWindow,
      setNameCollectionIsPinned,
    );
  };

  return (
    <div className="remove-collection-window">
      <div className="remove-collection-window__text">
        {isPinned
          ? "By pinning this collection, you will be able to select it on the Stamp page dropdown menu."
          : "This collection will no longer appear in the Stamp menu. You can always pin it again later."}
      </div>
      <div className="remove-collection-window__title">Proceed?</div>

      <div className="remove-collection-window__buttons rb-display-flex">
        <button
          className="button rb-btn rb-btn-black rb-display-flex rb-align-items-center"
          onClick={() => handleClickIsPinnedCollection()}
        >
          Yes
        </button>
        <button
          className="button rb-btn rb-btn-white rb-display-flex rb-align-items-center"
          onClick={() => handleClickIsPinnedCollectionClose()}
        >
          No
        </button>
      </div>
    </div>
  );
};

IsPinnedCollectionWindow.propTypes = {
  setShowIsPinnedWindow: PropTypes.func.isRequired,
  collectionID: PropTypes.number.isRequired,
  setNameCollectionIsPinned: PropTypes.func.isRequired,
  isPinned: PropTypes.bool.isRequired,
};
export default IsPinnedCollectionWindow;
