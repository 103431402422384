import PropTypes from "prop-types";
import React from "react";
import SearchableSelect from "../utils/searchableSelect";
import { URLs } from "../utils/apis";
import Help from "./Help";
import "../../../static/assets/scss/components/stamp-collection.scss";

const CollectionSelect = ({
  activateSelectCollection,
  onActivateSelectCollection,
  selectCollections,
  onSelectedOptionCollection,
  selectedOptionCollection,
  helpIsActive,
}) => {
  const handleCheckboxChange = (event) => {
    onActivateSelectCollection(event.target.checked);
  };
  // selecting Collection Name for Stamp page
  return (
    <div className="stamp-collection rb-display-flex rb-justify-content-center rb-flex-wrap-wrap">
      <div className="stamp-collection-activate-wrap">
        <input
          id="stamp-belongs"
          className="stamp-collection-activate"
          type="checkbox"
          checked={activateSelectCollection}
          onChange={handleCheckboxChange}
        />
        <div
          htmlFor="stamp-belongs"
          className="stamp-collection-activate-label rb-display-flex rb-align-items-center"
        >
          <label htmlFor="stamp-belongs">Stamp Belongs to Collection</label>{" "}
          {helpIsActive && (
            <Help
              position="right"
              text={{
                __html: `<p>You can assign your vBase Stamps to Collections (think of Collections like topics, for example: strategy1, strategy2) etc.</p>

<p>Someone verifying your stamps will be able to see how many Collections you've created and how many stamps belong to each Collection.</p>`,
              }}
              link=""
            />
          )}
        </div>
      </div>
      <div className="stamp-collection-wrap rb-display-flex">
        {/*Select component*/}
        <SearchableSelect
          selectCollections={selectCollections}
          disabled={!activateSelectCollection}
          onSelectedOptionCollection={onSelectedOptionCollection}
          selectedOptionCollection={selectedOptionCollection}
        />

        <a
          className="rb-display-flex rb-justify-content-center rb-align-items-center"
          href={URLs.USER_COLLECTIONS_URL}
        >
          +
        </a>
      </div>
    </div>
  );
};

CollectionSelect.propTypes = {
  activateSelectCollection: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func,
  selectCollections: PropTypes.array.isRequired,
  onSelectedOptionCollection: PropTypes.func.isRequired,
  helpIsActive: PropTypes.bool.isRequired,
  onActivateSelectCollection: PropTypes.func.isRequired,
  selectedOptionCollection: PropTypes.string,
};
export default CollectionSelect;
