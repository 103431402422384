import React, { useState, useEffect } from "react";
import Pagination from "../user-profile/Pagination";
import ReportBlock from "./ReportBlock";
import LogoVBSvg from "../../svg/LogoVBSvg";
import LogoBlockchainSvg from "../../svg/LogoBlockchainSvg";
import LogoPDFSvg from "../../svg/LogoPDFSvg";
import { reportsData } from "../../utils/apis";
const ReportContent = ({ staticBasePath }) => {
  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    allocations: [],
    current_page: 1,
    total_pages: 1,
    producer_identity: {
      name: "",
      url: "",
    },
    strategy_name: "",
    press_release_pdf: "",
    validation_information: {
      strategy_commits: "",
      other_strategies_commits: "",
      total_commits: "",
    },
    available_for_download: false,
    download_ZIP: "",
    download_single_CSV: "",
  });

  useEffect(() => {
    reportsData(1, setInputValues);
  }, []);

  // Handle pagination
  const handlePageChange = (newPage) => {
    reportsData(newPage, setInputValues); // Call the function with the new page number
  };

  return (
    <div className="reports">
      <div className="reports-text">
        <h1>Model Performance Authentication</h1>
        <p>
          The vBase app verifiably secures data, creating a timestamped record
          of your data’s existence. Build your credibility. Anyone with whom you
          share your file can validate its timestamp and integrity by checking
          the blockchain fingerprint.
        </p>
      </div>
      <div className="reports-content">
        <div className="reports-head rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
          <div className="reports-head-left">
            <h2>Strategy Information</h2>
            <div className="reports-head-left-block">
              <span>Strategy Producer Identity:</span>
              <b>{inputValues.producer_identity?.name}</b>
              {inputValues.producer_identity?.url && (
                <>
                  (
                  <a href={inputValues.producer_identity.url}>
                    Producer Profile
                  </a>
                  )
                </>
              )}
            </div>

            <div className="reports-head-left-block">
              <span>Strategy Name:</span>
              <b>{inputValues.strategy_name}</b>
            </div>
            <div className="reports-head-left-block reports-head-left-block-release">
              <span>Press Release Issued:</span>
              {inputValues.press_release_pdf && (
                <a href={inputValues.press_release_pdf}>
                  {inputValues.strategy_name}
                </a>
              )}
            </div>
          </div>
          <div className="reports-head-right">
            <h3>Validation Information</h3>
            <div className="reports-blocks rb-display-flex rb-justify-content-space-between">
              {inputValues?.validation_information?.strategy_commits && (
                <ReportBlock
                  Background="#88938F"
                  Color="#FFFFFF"
                  Text="Strategy Commits"
                  Number={inputValues?.validation_information?.strategy_commits}
                  position="top-right"
                  text="Strategy Commits Strategy Commits Strategy Commits Strategy Commits Strategy Commits"
                  link="#"
                />
              )}
              {inputValues?.validation_information
                ?.other_strategies_commits && (
                <ReportBlock
                  Background="#EDF0E5"
                  Color="#000000"
                  Text="Other Strategies commits"
                  Number={
                    inputValues?.validation_information
                      ?.other_strategies_commits
                  }
                  position="top-right"
                  text="Other Strategies commits Other Strategies commits Other Strategies commits Other Strategies commits"
                  link="#"
                />
              )}
              {inputValues?.validation_information?.total_commits && (
                <ReportBlock
                  Background="#1B2F28"
                  Color="#FFFFFF"
                  Text="Total Commits"
                  Number={inputValues?.validation_information?.total_commits}
                  position="top-right"
                  text="Total Commits Total Commits Total Commits Total Commits Total Commits "
                  link="#"
                />
              )}
            </div>
            <div className="reports-all">
              <div className="reports-all-title">
                all files available for download:
              </div>
              <div className="reports-all-content rb-display-flex rb-align-items-center rb-justify-content-flex-end">
                {inputValues?.available_for_download ? (
                  <>
                    <div className="reports-all-content-img rb-display-flex rb-justify-content-center rb-align-items-center">
                      <img
                        src={`${staticBasePath}public/images/svg/yes.svg`}
                        alt="Yes"
                      />
                    </div>
                    YES
                  </>
                ) : (
                  <>
                    <div className="reports-all-content-img reports-all-content-img-no rb-display-flex rb-justify-content-center rb-align-items-center">
                      <img
                        src={`${staticBasePath}public/images/svg/no.svg`}
                        alt="No"
                      />
                    </div>
                    NO
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="html-table-wrap">
          <div className="reports-table-head rb-display-flex rb-justify-content-space-between">
            <div className="reports-table-head-left">
              Committed target allocations
            </div>
            <div className="reports-table-head-right">
              {inputValues.download_ZIP && (
                <a href={inputValues.download_ZIP}>Download ZIP</a>
              )}
              {inputValues.download_single_CSV && (
                <a href={inputValues.download_single_CSV}>
                  Download Single CSV
                </a>
              )}
            </div>
          </div>
          {inputValues["allocations"].length > 0 ? (
            <div className="html-table">
              <table>
                <thead>
                  <tr>
                    <th>Target Allocations File</th>
                    <th>Trusted Timestamp</th>
                    <th>Proof of authenticity</th>
                  </tr>
                </thead>
                <tbody>
                  {inputValues["allocations"].map((allocation, index) => (
                    <tr key={index}>
                      <td>{allocation.target_allocations_file}</td>
                      <td>{allocation.trusted_timestamp}</td>
                      <td>
                        <a
                          href={allocation.proof_of_authenticity_link_vbase}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LogoVBSvg />
                        </a>
                        <a
                          href={
                            allocation.proof_of_authenticity_link_blockchain
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LogoBlockchainSvg />
                        </a>
                        <a
                          href={allocation.proof_of_authenticity_pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LogoPDFSvg />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available.</p>
          )}
        </div>
        {/* Pagination Controls */}
        {inputValues["total_pages"] > 1 && (
          <Pagination
            currentPage={inputValues["current_page"]}
            totalPages={inputValues["total_pages"]}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ReportContent;
